import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./stylesheets/LibraryFAQ.scss";

export default class LibraryFAQ extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expanded: false
        };
    }

    toggleExpanded = () => {
        this.setState({expanded: !this.state.expanded});
    }

    render(){

        const content = (
            <div id="library-faq">
                <h2>What is your inspiration for this project?</h2>
                <p>
                    A few years ago, I started a document with the chords to my favorite songs.
                    I wanted to see what aspects tied them together and with what tags I could group songs.
                    I wanted to share this information with the world, so I made this website!
                </p>

                <h2>But aren't songs more than their chord progressions?</h2>
                <p>
                    Yes, harmony is just the foundation of a good composition, and I often make notes
                    about other aspects I like in the “commentary” section.
                </p>
                <p>
                    With that being said, when I started conducting my “deep dives” to see why I like certain songs,
                    I found that my favorites almost always have interesting or emotional harmonic elements.
                    My palate is oriented towards satisfying harmony, while others might appreciate lyrics or rhythm
                    or production more. This webpage serves to explain one aspect of why I like certain songs.
                </p>

                <h2>How precise are these harmonic analyses?</h2>
                <ul>
                    <li>
                        Inversions (slash notation to indicate the note in the bass) are only used
                        when the bass is notable, such as stepwise motion that contributes to the overall sound.
                        “Bass” is relative to harmony; I do not notate basslines, much as I do not notate melody.
                    </li>
                    <li>
                        My analysis is made by ear, so there may be inaccuracies!
                    </li>
                    <li>
                        Many groups of notes can be spelled as different chords. I use whatever degree and quality
                        makes most sense as functional harmony.
                    </li>
                    <li>
                        The program does its best to use the correct enharmonic spellings (such as for notes in a
                        scale/key). However, sometimes there is no consensus in the broader musical community
                        (such as for the spelling of the chromatic scale), in which case the program sticks with one
                        spelling.
                    </li>
                </ul>

                <h2>Why jazz-style notation?</h2>
                <p>
                    I decided to make my notation similar to lead sheets, as one purpose of this website is to
                    provide a reference from which to play these songs. For example, for inversions, I chose to
                    specify what note is in the bass (e.g. C/G) rather than figured bass (e.g. C 6/4).
                </p>

                <h2>Can I suggest songs to add?</h2>
                <p>
                    This website is supposed to be a repository of <em>my</em> favorite songs, so I'm not accepting
                    direct contributions. However, if you email me a song you like (and maybe why you like it), and
                    I really get into it, I might consider adding it!
                </p>
                <p>
                    Get in touch:&nbsp;
                    <a href="mailto:eric@yoonicode.com">eric@yoonicode.com</a>
                </p>

                <h2>Where do you get song data?</h2>
                <p>
                    Analyses are made by ear! Sometimes for more complex songs, I’ll use tools like an EQ to help
                    me. I also will use sites like <a href="https://www.ultimate-guitar.com/">Ultimate Guitar</a>
                    &nbsp;or <a href="https://www.hooktheory.com/">HookTheory</a> (to which I sometimes contribute)
                    as a jumping-off point, but due to simplification and inaccuracies, I check all work done by
                    others. One other super helpful tool is this&nbsp;
                    <a href="https://www.onemotion.com/chord-player/">online chord progression player</a>, from
                    which I took inspiration for the way I represent chord shorthand.
                </p>

                <h2>Can I get raw song data?</h2>
                <p>
                    All song data is open-source in JSON format.&nbsp;
                    <a href="https://github.com/ericyoondotcom/JamChordLibrary/">View on GitHub</a>.&nbsp;
                    Not for commercial use.
                </p>

                <h2>What's up with the colors and graphics in Play mode?</h2>
                <p>
                    Each chord function has its own personality—the major 7 is celestial (hence the stars), while
                    the minor 7 is cool (like the night). The colors are in rainbow order, going from Do to Ti
                    and red to pink. Major chords are light, while minor chords are dark.
                </p>
            </div>
        );

        return (
            <>
                <button className="nostyle dropdown-button" onClick={this.toggleExpanded}>
                    Frequently Asked Questions
                    <FontAwesomeIcon icon={this.state.expanded ? faChevronUp : faChevronDown} />
                </button>
                {
                    this.state.expanded && content
                }
            </>
        );
    }
}
