import YoutubeMusicLogo from "../resources/yt_music_logo.png";
import YoutubeLogo from "../resources/youtube_logo.png";
import SpotifyLogo from "../resources/spotify_logo.png";

export const CHORD_LIBRARY_DATA = process.env.REACT_APP_LIBRARY_DATA_URL || "https://raw.githubusercontent.com/ericyoondotcom/JamChordLibrary/main";

export const FIREBASE_CONFIG = {
    apiKey: "AIzaSyChZhKtaMzjM-6-KTvwcgUp36XDzSNlN3w",
    authDomain: "jam-together.firebaseapp.com",
    databaseURL: "https://jam-together.firebaseio.com",
    projectId: "jam-together",
    storageBucket: "jam-together.appspot.com",
    messagingSenderId: "947676043639",
    appId: "1:947676043639:web:cdb9eae2022ff800e6e972",
    measurementId: "G-3BXSHJF7EX"
};

export function easeInOutCubic(x) {
    return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
};

export function addParamToURL(param, value, history, location) {
    const params = new URLSearchParams(location.search);
    params.set(param, value);
    history.replace({
        pathname: location.pathname,
        search: params.toString()
    });
};

export const OUTLINK_IMAGES = {
    "youtube-music": YoutubeMusicLogo,
    "youtube": YoutubeLogo,
    "spotify": SpotifyLogo
};

export const RELATIVE_OR_NOTE_NAMES_DROPDOWN_OPTIONS = [
    { key: "relative", text: "Relative" },
    { key: "notes", text: "Note names" }
];
export const SIMPLIFY_CHORDS_DROPDOWN_OPTIONS = [
    { key: false, text: "Chords" },
    { key: true, text: "Simplified chords" },
];
