import React from "react";
import PropTypes from "prop-types";
import SongProfile, { SongProfileTag } from "./SongProfile";
import Button from "./Button";
import { Link } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

class LibrarySingleSong extends React.Component {
    render(){
        const {songs} = this.props;

        const songId = decodeURIComponent(this.props.match.params.songId);
        if(!(songId in songs)) {
            return (
                <div id="about-area">
                    <h2>Song not found</h2>
                    <Link to="/library"><Button content="Back to Library" icon={faArrowRight} iconPosition="right" /></Link>
                </div>
            )
        }

        return (
            <>
                <div id="about-area">
                    <h2>Viewing song</h2>
                    <Link to="/library"><Button content="Back to Library" icon={faArrowRight} iconPosition="right" /></Link>
                </div>
                <SongProfile
                    song={songs[songId]}
                    songs={songs}
                    songId={songId}
                    expanded={true}
                    defaultSettings={this.props.defaultSettings}
                />
            </>
        );
    }
}

LibrarySingleSong.propTypes = {
    songs: PropTypes.object.isRequired,
    tagNames: PropTypes.array.isRequired,
    songNamesByTag: PropTypes.object.isRequired,
    onDefaultSettingChanged: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired
};

export default LibrarySingleSong;