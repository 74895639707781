import React from "react";
import PropTypes from "prop-types";
import "./stylesheets/LandingPageLink.scss";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

class LandingPageLink extends React.Component {
    render(){
        const {title, description, url, icon} = this.props;
        return (
            <Link to={url} className="landing-page-link">
                <div>
                    <div className="icon">
                        <FontAwesomeIcon icon={icon} />
                    </div>
                    <div className="text">
                        <h2>{title}</h2>
                        <p>{description}</p>
                    </div>
                </div>
            </Link>
        );
    }
}

LandingPageLink.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired
};

export default LandingPageLink;