import React from "react";
import PropTypes from "prop-types";
import "./stylesheets/ProgressionPlayer.scss";
import { CHORD_QUALITIES, getNotesInChord } from "../constants/chord-definitions";
import ChordDiagram from "./ChordDiagram";
import { getPitchFromDegree, PITCH_NAMES_LIST } from "../constants/note-definitions";
import { serializeChord, simplifyChord } from "../constants/chord-serializer";
import INSTRUMENTS from "../constants/instruments";
import hslToHex from "hsl-to-hex";
import fontColorContrast from "font-color-contrast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faPause, faPlay, faRefresh, faX } from "@fortawesome/free-solid-svg-icons";
import Button from "./Button";
import * as Tone from "tone";
import { PIANO_SAMPLE_URLS } from "../resources/piano";

class ProgressionPlayer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            currentChordIndex: 0,
            autoplay: false,
            ready: false
        };
        this.repeatCounter = 0;
        this.loadTone();
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyDown);
    }
    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    handleKeyDown = (e) => {
        if(!this.props.visible) return;
        if(e.code === "ArrowRight") this.scroll(true);
        else if(e.code === "ArrowLeft") this.scroll(false);
        else if(e.code === "Space") this.toggleAutoPlay();
    }
    
    loadTone = async () => {
        this.sampler = new Tone.Sampler({
            urls: PIANO_SAMPLE_URLS,
            release: 1
        }).toDestination();
        await Tone.loaded();
        await this.setState({ ready: true });
    }

    startTone = async () => {
        await Tone.start();
    }

    setSchedulerInterval = () => {
        this.schedulerInterval = setInterval(this.schedulerCallback, 10);
        this.lastAdvanceTime = new Date(); // Last timestamp the chord was advanced
        this.lastPlayTime = new Date(); // Last timestamp the chord was played
    }

    schedulerCallback = () => {
        const {sequence} = this.props;
        const {currentChordIndex} = this.state;

        const millisPerBeat = 1000 * 60 / this.props.tempo;
        const now = new Date();
        let thisChord = sequence[currentChordIndex];
        const millisInChord = millisPerBeat * thisChord.duration.numerator / thisChord.duration.denominator;
        
        if(now.getTime() - this.lastAdvanceTime.getTime() > millisInChord) {
            this.lastPlayTime = new Date();
            this.lastAdvanceTime = new Date();
            
            const nextChordIndex = this.findNextPlayableChord(currentChordIndex, true);
            if(nextChordIndex === -1) {
                clearInterval(this.schedulerInterval);
                this.setState({ autoplay: false });
                return;
            }
            this.setState({ currentChordIndex: nextChordIndex });
            this.playChord(sequence[nextChordIndex]);
        } else if(now.getTime() - this.lastPlayTime.getTime() > millisPerBeat) {
            this.lastPlayTime = new Date();
            this.playChord(thisChord);
        }
    }

    playChord = (chord) => {
        if(chord.type !== "chord") return;
        const quality = CHORD_QUALITIES[chord.quality];
        const notesInChord = getNotesInChord(chord);
        const notes = notesInChord.map(note => {
            const pitch = chord.root + note + this.props.keyNum;
            // const octave = (3 + (pitch / 12)).toString();
            const octave = 4; // TODO: play extensions in higher octaves
            return PITCH_NAMES_LIST[pitch % 12].toUpperCase() + octave;
    });
        // Play bass
        const noteInBass = notesInChord[chord.inversion];
        notes.push(
            PITCH_NAMES_LIST[(chord.root + noteInBass + this.props.keyNum) % 12].toUpperCase() + "2"
        )
        const secsPerBeat = 60 / this.props.tempo;
        this.sampler.triggerAttackRelease(notes, secsPerBeat);
    }

    onShadeClick = (e) => {
        if(e.currentTarget !== e.target) return;
        this.close();
    }
    
    close = (e) => {
        if(this.props.onClose) this.props.onClose();
        if(this.state.autoplay) this.toggleAutoPlay();
        this.setState({ currentChordIndex: 0 });
        this.repeatCounter = 0;
    }
    
    scroll = async (direction) => {
        if(!this.state.ready) return;
        this.startTone();

        const nextChordIndex = this.findNextPlayableChord(this.state.currentChordIndex, direction);
        if(nextChordIndex === -1) return;
        this.setState({ currentChordIndex: nextChordIndex });
        this.playChord(this.props.sequence[nextChordIndex]);
        this.lastAdvanceTime = new Date();
        this.lastPlayTime = new Date();
    }

    findNextPlayableChord = (currentChordIndex, direction, ignoreRepeats = false) => {
        // ignoreRepeats is to prevent infinite loop if user puts a repeat right after a break
        const {sequence} = this.props;
        let nextChordIndex = currentChordIndex + (direction ? 1 : -1);
        while(true) {
            if(nextChordIndex < 0 || nextChordIndex === sequence.length) {
                return -1;
            }
            const nextChord = sequence[nextChordIndex];
            if(nextChord.type === "chord") {
                break;
            } else if(nextChord.type === "repeat" && direction && !ignoreRepeats) {
                if(this.repeatCounter >= nextChord.times - 1) { // minus one because (x2) actually means repeat once
                    this.repeatCounter = 0;
                    // allow loop to continue going to find next chord object
                } else {
                    // else go back to find the break where the repeat corresponds to
                    nextChordIndex = this.findFirstChordAfterLatestBreak(nextChordIndex);
                    this.repeatCounter++;
                    break;
                }
            }
            nextChordIndex += (direction ? 1 : -1);
        }
        return nextChordIndex;
    }

    findFirstChordAfterLatestBreak = (repeatPosition) => {
        const {sequence} = this.props;
        let latestBreak = repeatPosition - 1;
        while(true) {
            const thisChord = sequence[latestBreak];
            // treat repeats like breaks in case user puts one repeat after another
            if(thisChord.type === "break" || thisChord.type === "repeat") break;
            latestBreak--;
            if(latestBreak < 0) break;
        }
        // After we go back and find the latest break, find the next chord that's not a break
        return this.findNextPlayableChord(latestBreak, true, true);
    }

    toggleAutoPlay = () => {
        if(!this.state.ready) return;
        this.startTone();
        if(this.state.autoplay) {
            // Stop autoplay
            if(this.schedulerInterval) clearInterval(this.schedulerInterval);
            this.setState({ autoplay: false });
        } else {
            // Start autoplay
            this.setSchedulerInterval();
            this.setState({ autoplay: true });
            if(this.state.currentChordIndex === this.props.sequence.length - 1) {
                this.setState({ currentChordIndex: 0 });
                this.playChord(this.props.sequence[0]);
            } else {
                this.playChord(this.props.sequence[this.state.currentChordIndex]);
            }
        }
    }

    render(){
        const {visible, keyNum, relative, instrument, sequence, simplify} = this.props;
        const {currentChordIndex, autoplay, ready} = this.state;
        const instrumentObject = INSTRUMENTS[this.props.instrument];
        
        if(!sequence || currentChordIndex >= sequence.length || currentChordIndex < 0) {
            return (
                <div className={`progression-player ${visible ? 'visible' : null}`} onClick={this.onShadeClick}>
                    <div className="content error">
                        <button className="close-button nostyle" onClick={this.close} >
                            <FontAwesomeIcon icon={faX} />
                        </button>
                        <h1>No chords to play 👀</h1>
                    </div>
                </div>
            )
        }

        let thisChord = sequence[currentChordIndex];
        if(thisChord.type !== "chord") return null;

        const pitch = getPitchFromDegree(keyNum, thisChord.root);
        const chordName = `${pitch}${thisChord.quality}`;
        const displayName = serializeChord(thisChord, relative ? null : keyNum, true);
        const quality = CHORD_QUALITIES[thisChord.quality];
        const hue = (parseFloat(thisChord.root) / PITCH_NAMES_LIST.length);
        const bgHex = hslToHex(hue * 360, quality.color.saturation * 100, quality.color.lightness * 100);
        const fgColor = fontColorContrast(bgHex);

        const isLastChord = currentChordIndex === sequence.length - 1;
        const isFirstChord = currentChordIndex === 0;

        return (
            <div className={`progression-player ${visible ? 'visible' : null}`} onClick={this.onShadeClick}>
                <div className="content" style={{
                        color: fgColor
                    }}>
                    <button className="close-button nostyle" onClick={this.close} style={{
                        color: fgColor
                    }}>
                        <FontAwesomeIcon icon={faX} />
                    </button>
                    <div className="background bg-color" style={{
                        backgroundColor: bgHex
                    }} />
                    <div className="background bg-image" style={{
                        backgroundImage: `url(${quality.image})`
                    }} />
                    <div className="foreground">
                        <div
                            className="slideshow"
                            style={{
                                gridTemplateColumns: `repeat(${sequence.length}, 33.33%)`,
                                transform: `translateX(-${currentChordIndex * 33.33}%)`
                            }}
                        >
                            {
                                sequence.map((chord, index) => {
                                    if(simplify) chord = simplifyChord(chord);
                                    if(chord.type === "break") {
                                        return (
                                            <div className={`slide break ${index === currentChordIndex ? "current" : ""}`} key={index}>
                                                <div className="vline" style={{
                                                    borderColor: fgColor
                                                }} />
                                            </div>
                                        );
                                    }
                                    if(chord.type === "repeat") {
                                        return (
                                            <div className={`slide repeat ${index === currentChordIndex ? "current" : ""}`} key={index}>
                                                <FontAwesomeIcon icon={faRefresh} className="repeat-icon" />
                                                <p>x{chord.times}</p>
                                            </div>
                                        )
                                    }
                                    if(chord.type === "chord"){
                                        const pitch = getPitchFromDegree(keyNum, chord.root);
                                        const chordName = `${pitch}${chord.quality}`;
                                        const displayName = serializeChord(chord, relative ? null : keyNum, true);
                                        return (
                                            <div className={`slide chord ${index === currentChordIndex ? "current" : ""}`} key={index}>
                                                <p className="chord-name">{displayName}</p>
                                                <ChordDiagram
                                                    instrument={instrument}
                                                    chord={chordName}
                                                    width={`${instrumentObject.defaultScale * 200}px`}
                                                    scale={1}
                                                    lineColor={fgColor}
                                                />
                                            </div>
                                        );
                                    }
                                    return null;
                                })
                            }
                        </div>
                        <div className="controls">
                            <button
                                className={`nostyle scroll-button ${isFirstChord || !ready ? "disabled" : ""}`}
                                onClick={() => this.scroll(false)}
                                style={{
                                    color: fgColor
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </button>
                            <Button
                                disabled={!ready}
                                jumbo
                                icon={autoplay ? faPause : faPlay}
                                content={autoplay ? "Pause" : "Play"}
                                onClick={this.toggleAutoPlay}
                            />
                            <button
                                className={`nostyle scroll-button ${isLastChord || !ready ? "disabled" : ""}`}
                                onClick={() => this.scroll(true)}
                                style={{
                                    color: fgColor
                                }}
                            >
                                <FontAwesomeIcon icon={faArrowRight} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProgressionPlayer.propTypes = {
    keyNum: PropTypes.number.isRequired,
    instrument: PropTypes.string.isRequired,
    sequence: PropTypes.arrayOf(PropTypes.object).isRequired,
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    relative: PropTypes.bool,
    tempo: PropTypes.number.isRequired,
    simplify: PropTypes.bool
};

export default ProgressionPlayer;