import React from "react";
import PropTypes from "prop-types";
import Popup from "./Popup";

class ProfilePic extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        return (
            <div className="profile-pic">
                <Popup trigger={
                    <img src={this.props.url} style={{
                        ...this.props.style,
                        width: this.props.iconSize,
                        height: this.props.iconSize
                    }} />
                } content={
                    this.props.hoverText
                } />
            </div>
        );
    }
}

ProfilePic.propTypes = {
    url: PropTypes.string.isRequired,
    hoverText: PropTypes.string.isRequired,
    iconSize: PropTypes.string.isRequired,
    style: PropTypes.object
};

ProfilePic.defaultProps = {
    iconSize: "40px",
    style: {}
};

export default ProfilePic;