import { CHORD_QUALITIES, getNotesInChord } from "./chord-definitions";
import { PITCH_NAMES_LIST } from "./note-definitions";

export function autoVoiceProgression(sequence) {
    const filteredSequence = sequence.filter(i => i.type === "chord");
    const results = [];
    autoVoiceHelper(filteredSequence, [], results);
    
    // Get best result
    const [score, inversions] = results.reduce((a, b) =>
        a[0] > b[0] ? a : b
    );
    
    return filteredSequence.map((chord, idx) => ({
        ...chord,
        inversion: inversions[idx]
    }));
}

function autoVoiceHelper(sequence, inversions, results) {
    const index = inversions.length;
    if(index === sequence.length) {
        const score = evaluateProgression(sequence, inversions);
        results.push([score, inversions]);
    } else {
        const chord = sequence[index];
        const qualityInfo = CHORD_QUALITIES[chord.quality];
        const notesInChord = getNotesInChord(chord);
        for(let i = 0; i < notesInChord.length; i++) {
            autoVoiceHelper(sequence, [...inversions, i], results);
        }
    }
}

function evaluateProgression(sequence, inversions) {
    let score = 0;
    for(let i = 0; i < inversions.length; i++) {
        if(i < inversions.length - 1) {
            const notesInChordA = getNotesInChord(sequence[i]);
            const notesInChordB = getNotesInChord(sequence[i + 1]);
            const pitchesA = notesInChordA.map((note, noteIdx) => note + sequence[i].root + (noteIdx < inversions[i] ? 12 : 0));
            const pitchesB = notesInChordB.map((note, noteIdx) => note + sequence[i + 1].root + (noteIdx < inversions[i + 1] ? 12 : 0));
            const pitchesAShfitedDown = pitchesA.map(note => note -= 12);
            const pitchesAShiftedUp = pitchesA.map(note => note += 12);
            score += Math.max(
                comparePitches(pitchesA, pitchesB),
                comparePitches(pitchesAShfitedDown, pitchesB),
                comparePitches(pitchesAShiftedUp, pitchesB)
            );
        }
        if(sequence[i].root === 0 && inversions[i] === 0) score += 10; // Bonus for I chord in root position
    }
    return score;
}

function comparePitches(pitchesA, pitchesB) {
    let score = 0;
    for(const a of pitchesA) {
        for(const b of pitchesB) {
            // TODO: should each note only be able to used once?
            if(a === b) score += 3;
            else if(Math.abs(a - b) === 1) score += 2;
            else if(Math.abs(a - b) === 2) score += 1;
        }
    }
    return score;
}
