import React from "react";
import "./stylesheets/VoicingsPage.scss";
import { parseQuickInput } from "../constants/chord-serializer";
import {DO, DI, RAH, RE, RI, ME, MI, FA, FI, SE, SOL, SI, LE, LA, LI, TE, TI} from "../constants/solfege";
import { DEGREE_NAMES_LIST, getEnharmonicNameFromDegree, getPitchDisplayName, getPitchFromDegree, PITCH_NAMES, PITCH_NAMES_LIST } from "../constants/note-definitions";
import INSTRUMENTS from "../constants/instruments";
import Input from "../components/Input";
import InstrumentDropdown from "../components/InstrumentDropdown";
import CircleSelector from "../components/CircleSelector";
import Button from "../components/Button";
import { CHORD_QUALITIES } from "../constants/chord-definitions";
import ChordDiagram from "../components/ChordDiagram";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { autoVoiceProgression } from "../constants/autovoicer";
import ChordOverview from "../components/ChordOverview";
import { addParamToURL } from "../constants/consts";

export default class VoicingsPage extends React.Component {
    constructor(props){
        super(props);
        const urlParams = new URLSearchParams(props.location.search);
        this.state = {
            instrument: urlParams.has("instrument") && (urlParams.get("instrument") in INSTRUMENTS) ? urlParams.get("instrument") : Object.keys(INSTRUMENTS)[0],
            sequence: [], // a sequence of chord objects.
            result: [],
            quickInputValue: "dm7 g7 cmaj7",
            errorChord: null,
            firstError: null,
            key: PITCH_NAMES["c"]
        };
        if(this.state.quickInputValue.length > 0){
            this.state = {
                ...this.state,
                ...parseQuickInput(this.state.quickInputValue, this.state.key)
            };
        }
    }

    run = () => {
        this.setState({
            result: autoVoiceProgression(this.state.sequence, this.state.key)
        });
    }
    
    render(){
        return (
            <div id="voicings-main-content">
                <div className="rails">
                    <h2 className="subtitle centered gradient-text">Voicings Generator</h2>
                    <Input error={this.state.firstError != null} autoFocus value={this.state.quickInputValue} placeholder="iim7 G7 Cmaj7" onChange={e => {
                        this.setState({
                            quickInputValue: e.target.value,
                            result: [],
                            ...parseQuickInput(e.target.value, this.state.key)
                        });
                    }} style={{
                        width: "100%",
                        fontSize: "30px"
                    }} />
                    <p className="centered error" id="quick-input-error">{
                        this.state.firstError && `Error parsing "${this.state.errorChord}": ${this.state.firstError.message}`
                    }</p>
                    <div className="options-panel">
                        <div>
                            <p>Instrument</p>
                            <InstrumentDropdown value={this.state.instrument} onChange={val => {
                                addParamToURL("instrument", val, this.props.history, this.props.location);
                                this.setState({instrument: val});
                            }} />
                        </div>
                        <div>
                            <p>Key</p>
                            <CircleSelector labels={
                                PITCH_NAMES_LIST.map(i => getPitchDisplayName(i).toUpperCase())
                            } value={this.state.key} onChange={(val) => {
                                this.setState({
                                    key: val,
                                    result: [],
                                    ...parseQuickInput(this.state.quickInputValue, val)
                                });
                                // TODO: Transpose absolute chords
                            }} />
                        </div>
                    </div>
                    <div id="centered-button">
                        <Button
                            primary
                            icon={faArrowRight}
                            iconPosition="right"
                            content="Auto voice"
                            onClick={this.run}
                        />
                    </div>
                    <ChordOverview
                        sequence={this.state.result}
                        keyNum={this.state.key}
                        instrument={this.state.instrument}
                    />
                </div>
            </div>
        );
    }
}