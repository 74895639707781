import React from "react";
import PropTypes from "prop-types";
import { RELATIVE_OR_NOTE_NAMES_DROPDOWN_OPTIONS, SIMPLIFY_CHORDS_DROPDOWN_OPTIONS } from "../constants/consts";
import { getPitchDisplayName, PITCH_NAMES_LIST } from "../constants/note-definitions";
import InstrumentDropdown from "./InstrumentDropdown";
import Dropdown from "./Dropdown";
import CircleSelector from "./CircleSelector";
import Input from "./Input";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class LibraryOptionsPanel extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expanded: false
        };
    }

    toggleExpanded = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const { onDefaultSettingChanged, defaultSettings } = this.props;
        const { instrument, mode, key, tempo, simplify } = defaultSettings;

        const content = (
            <div className="options-panel">
                <div>
                    <p>Instrument</p>
                    <InstrumentDropdown value={instrument} onChange={newVal => {
                        onDefaultSettingChanged("instrument", newVal);
                    }} />
                </div>
                <div>
                    <p>Mode</p>
                    <Dropdown
                        value={mode}
                        placeholder="Select an option"
                        options={RELATIVE_OR_NOTE_NAMES_DROPDOWN_OPTIONS}
                        onChange={(newVal) => {
                            onDefaultSettingChanged("mode", newVal);
                        }}
                    />
                </div>
                <div>
                    <p>Key</p>
                    <CircleSelector labels={
                        PITCH_NAMES_LIST.map(i => getPitchDisplayName(i).toUpperCase())
                    } value={key} onChange={(val) => {
                        onDefaultSettingChanged("key", val);
                    }} />
                </div>
                <div>
                    <p>Tempo</p>
                    <Input
                        value={tempo}
                        type="number"
                        onChange={(e) => {
                            let parsed = parseInt(e.target.value);
                            if (isNaN(parsed)) return;
                            onDefaultSettingChanged("tempo", parsed);
                        }}
                        style={{
                            width: "50px"
                        }}
                    />
                </div>
                <div>
                    <p>Simplify chords</p>
                    <Dropdown
                        value={simplify}
                        placeholder="Select an option"
                        options={SIMPLIFY_CHORDS_DROPDOWN_OPTIONS}
                        onChange={(newVal) => {
                            onDefaultSettingChanged("simplify", newVal);
                        }}
                    />
                </div>
            </div>
        );

        return (
            <>
                <button className="nostyle dropdown-button" onClick={this.toggleExpanded}>
                    Global settings
                    <FontAwesomeIcon icon={this.state.expanded ? faChevronUp : faChevronDown} />
                </button>
                {
                    this.state.expanded && content
                }
            </>
        );
    }
}

LibraryOptionsPanel.propTypes = {
    onDefaultSettingChanged: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired
};
