import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import firebase from "firebase/app";
import { FIREBASE_CONFIG } from "./constants/consts";
import LandingPage from "./pages/LandingPage";
import NotFoundPage from "./pages/NotFoundPage";
import MyProjectsPage from "./pages/MyProjectsPage";
import EditorPage from "./pages/EditorPage";
import SettingsPage from "./pages/SettingsPage";
import AuthPage from "./pages/AuthPage";
import PrivateRoute from "./components/PrivateRoute";
import OnboardingPage from "./pages/OnboardingPage";
import ChordChartPage from "./pages/ChordChartPage";
import ChordTabPage from "./pages/ChordTabPage";
import VoicingsPage from "./pages/VoicingsPage";
import ChordLibraryPage from "./pages/ChordLibraryPage";

export default class App extends React.Component {
	constructor(props){
		super(props);
		if(firebase.apps.length == 0){
			firebase.initializeApp(FIREBASE_CONFIG);
		}
	}

	render() {
		return (
			<Router>
				<Switch>
					<Route path="/" exact component={LandingPage} />
					<Route path="/projects" exact>
						<PrivateRoute component={MyProjectsPage} />
					</Route>
					<Route path="/studio/:projectId" exact component={EditorPage} />
					<Route path="/settings" exact>
						<PrivateRoute component={SettingsPage} />
					</Route>
					<Route path="/auth" exact component={AuthPage} />
					<Route path="/onboarding" exact component={OnboardingPage} />
					<Route path="/chart/:instrument?" exact component={ChordChartPage} />
					<Route path="/tab" exact component={ChordTabPage} />
					<Route path="/voicings" exact component={VoicingsPage} />
					<Route path="/library" component={ChordLibraryPage} />
					{/* 404 page */}
					<Route component={NotFoundPage} />
				</Switch>
			</Router>
		);
	}
}