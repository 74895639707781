import React from "react";
import PropTypes from "prop-types";
import "./stylesheets/ChordChartPage.scss";
import ChordDiagram from "../components/ChordDiagram";
import {DO, DI, RAH, RE, RI, ME, MI, FA, FI, SE, SOL, SI, LE, LA, LI, TE, TI} from "../constants/solfege";
import { DEGREE_NAMES_LIST, getEnharmonicNameFromDegree, getPitchDisplayName, getPitchFromDegree, PITCH_NAMES, PITCH_NAMES_LIST } from "../constants/note-definitions";
import INSTRUMENTS from "../constants/instruments";
import { CHORD_QUALITIES } from "../constants/chord-definitions";
import Dropdown from "../components/Dropdown";
import CircleSelector from "../components/CircleSelector";
import Button from "../components/Button";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import InstrumentDropdown from "../components/InstrumentDropdown";
import { addParamToURL } from "../constants/consts";

class ChordChartPage extends React.Component {
    constructor(props){
        super(props);
        const urlParams = new URLSearchParams(props.location.search);
        this.state = {
            displayedDegrees: urlParams.has("degrees") ? JSON.parse(decodeURIComponent(urlParams.get("degrees"))) : [DO, RE, MI, FA, SOL, LA, TI],
            key: urlParams.has("key") && (urlParams.get("key") in PITCH_NAMES) ? PITCH_NAMES[urlParams.get("key")] : PITCH_NAMES["c"],
            displayedQualities: urlParams.has("qualities") ? JSON.parse(decodeURIComponent(urlParams.get("qualities"))) : ["maj", "min", "dom7", "maj7", "m7"],
            printMode: false
        };
    }

    enablePrintMode = async () => {
        await this.setState({printMode: true});
        window.print();
    }
    
    render(){
        const {printMode, displayedDegrees, displayedQualities, key} = this.state;
        const instrument = this.props.match.params.instrument;
        const instrumentSelectPanel = (
            <div id="instrument-select-panel">
                <InstrumentDropdown value={instrument} onChange={val => {
                    window.location.href = `/chart/${val}` + window.location.search
                }} />
            </div>
        );
        if(!instrument || !(instrument in INSTRUMENTS)){
            return (
                <div id="chord-chart-main-content">
                    <div className="rails">
                        <h2 className="subtitle centered gradient-text">Chord Chart</h2>
                        {instrumentSelectPanel}
                    </div>
                </div>
            );
        }
        return (
            <div id="chord-chart-main-content" className={`${printMode ? "print-mode" : ""}`}>
                <div className="rails">
                    {
                        printMode ?
                            null : 
                            <h2 className={`subtitle centered ${printMode ? "accent" : "gradient-text"}`}>Chord Chart</h2>
                    }
                    {instrumentSelectPanel}
                    <div className="options-panel">
                        <div>
                            <p>Key</p>
                            <CircleSelector labels={
                                PITCH_NAMES_LIST.map(i => getPitchDisplayName(i).toUpperCase())
                            } value={key} onChange={(val) => {
                                addParamToURL("key", PITCH_NAMES_LIST[val], this.props.history, this.props.location);
                                this.setState({key: val});
                            }} />
                        </div>
                        <div>
                            <p>Degrees</p>
                            <Dropdown
                                multiple
                                maxDisplayCount={3}
                                value={displayedDegrees}
                                options={DEGREE_NAMES_LIST.map((degree, idx) => ({
                                    key: idx,
                                    text: `${degree.toUpperCase()} (${getPitchDisplayName(getEnharmonicNameFromDegree(key, idx)).toUpperCase()})`
                                }))}
                                placeholder="None"
                                onChange={(entry, active) => {
                                    const newVal = [...displayedDegrees];
                                    if(active) {
                                        newVal.push(entry);
                                        newVal.sort((a, b) => a - b);
                                    } else {
                                        newVal.splice(newVal.indexOf(entry), 1);
                                    }
                                    addParamToURL("degrees", encodeURIComponent(JSON.stringify(newVal)), this.props.history, this.props.location);
                                    this.setState({displayedDegrees: newVal});
                                }}
                            />
                        </div>
                        <div>
                            <p>Qualities</p>
                            <Dropdown
                                multiple
                                maxDisplayCount={3}
                                value={displayedQualities}
                                options={Object.keys(CHORD_QUALITIES).map((quality) => ({
                                    key: quality,
                                    text: CHORD_QUALITIES[quality].displayName
                                }))}
                                placeholder="None"
                                onChange={(entry, active) => {
                                    const newVal = [...displayedQualities];
                                    if(active) {
                                        newVal.push(entry);
                                    } else {
                                        newVal.splice(newVal.indexOf(entry), 1);
                                    }
                                    addParamToURL("qualities", encodeURIComponent(JSON.stringify(newVal)), this.props.history, this.props.location);
                                    this.setState({displayedQualities: newVal});
                                }}
                            />
                        </div>
                        <div>
                            <p>Print</p>
                            <Button content="Print chart" subtle icon={faPrint} onClick={this.enablePrintMode} />
                        </div>
                    </div>
                </div>
                <table id="main-table">
                    <tbody>
                        {
                            displayedDegrees.map(degree => {
                                const pitch = getPitchFromDegree(key, degree);
                                return (
                                    <tr key={pitch}>
                                        <td className="degree-labels">
                                            <p>{DEGREE_NAMES_LIST[degree].toUpperCase()}</p>
                                        </td>
                                        {
                                            displayedQualities.map(quality => {
                                                const chord = `${pitch}${quality}`;
                                                const qualityInfo = CHORD_QUALITIES[quality];
                                                let pitchName = getPitchDisplayName(getEnharmonicNameFromDegree(key, degree));
                                                pitchName = qualityInfo.capitalize ? pitchName.toUpperCase() : pitchName.toLowerCase();
                                                return (
                                                    <td key={quality}>
                                                        <p>
                                                            <b>{pitchName}</b>{qualityInfo.aliases[0]}
                                                        </p>
                                                        <ChordDiagram
                                                            instrument={instrument}
                                                            chord={chord}
                                                            width={INSTRUMENTS[instrument].defaultScale * 90}
                                                            scale={0.5}
                                                            lineColor={printMode ? "#000000" : "#ffffff"}
                                                        />
                                                    </td>
                                                );
                                            })
                                        }
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

ChordChartPage.propTypes = {
    
};

export default ChordChartPage;