const STR_E = 0;
const STR_A = 1;
const STR_D = 2;
const STR_G = 3;
const STR_B = 4;
const STR_e = 5;

/*
{
    strum: [true, true, true, true, true, true],
    fingers: [
        {finger: 1, string: STR_E, fret: 1},
        {finger: 1, string: STR_A, fret: 1},
        {finger: 1, string: STR_D, fret: 1},
        {finger: 1, string: STR_G, fret: 1},
        {finger: 1, string: STR_B, fret: 1},
        {finger: 1, string: STR_e, fret: 1},
    ],
    barres: [
        {finger: 1, fret: 1, from: STR_E, to: STR_e}
    ],
    shift: 0
}
*/

export const GUITAR_CHORD_DIAGRAMS = {
    "amaj": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_D, fret: 2},
            {finger: 3, string: STR_G, fret: 2},
            {finger: 4, string: STR_B, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "amin": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_D, fret: 2},
            {finger: 3, string: STR_G, fret: 2},
            {finger: 1, string: STR_B, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    // "asus2": {},
    // "asus4": {},
    // "adim": {},
    // "a+": {},
    "adom7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_e, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_D, to: STR_B}
        ],
        shift: 0
    },
    "amaj7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_D, fret: 2},
            {finger: 1, string: STR_G, fret: 1},
            {finger: 3, string: STR_B, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "am7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_D, fret: 2},
            {finger: 1, string: STR_B, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    // "ammaj7": {},
    // "adim7": {},
    // "ahdim7": {},

    "bbmaj": {
        strum: [false, true, true, true, true, false],
        fingers: [
            {finger: 1, string: STR_A, fret: 1},
        ],
        barres: [
            {finger: 3, fret: 3, from: STR_D, to: STR_B}
        ],
        shift: 0
    },
    "bbmin": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_D, fret: 3},
            {finger: 4, string: STR_G, fret: 3},
            {finger: 2, string: STR_B, fret: 2},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_E, to: STR_e}
        ],
        shift: 0
    },
    // "bbsus2": {},
    // "bbsus4": {},
    // "bbdim": {},
    // "bb+": {},
    "bbdom7": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_E, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 3, from: STR_D, to: STR_B}
        ],
        shift: 0
    },
    "bbmaj7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_D, fret: 3},
            {finger: 2, string: STR_G, fret: 2},
            {finger: 4, string: STR_B, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_A, to: STR_e}
        ],
        shift: 0
    },
    "bbm7": {
        strum: [false, false, true, true, true, true],
        fingers: [],
        barres: [
            {finger: 1, fret: 6, from: STR_D, to: STR_e}
        ],
        shift: 5
    },
    // "bbmmaj7": {},
    // "bbdim7": {},
    // "bbhdim7": {},

    "bmaj": {
        strum: [false, true, true, true, true, false],
        fingers: [
            {finger: 1, string: STR_A, fret: 2},
        ],
        barres: [
            {finger: 3, fret: 4, from: STR_D, to: STR_B}
        ],
        shift: 0
    },
    "bmin": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_D, fret: 4},
            {finger: 4, string: STR_G, fret: 4},
            {finger: 2, string: STR_B, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_A, to: STR_e}
        ],
        shift: 0
    },
    // "bsus2": {},
    // "bsus4": {},
    // "bdim": {},
    // "b+": {},
    "bdom7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_A, fret: 2},
            {finger: 1, string: STR_D, fret: 1},
            {finger: 3, string: STR_G, fret: 2},
            {finger: 4, string: STR_e, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "bmaj7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_D, fret: 4},
            {finger: 2, string: STR_G, fret: 3},
            {finger: 4, string: STR_B, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_A, to: STR_e}
        ],
        shift: 0
    },
    "bm7": {
        strum: [false, false, true, true, true, true],
        fingers: [],
        barres: [
            {finger: 1, fret: 7, from: STR_D, to: STR_e}
        ],
        shift: 6
    },
    // "bmmaj7": {},
    // "bdim7": {},
    // "bhdim7": {},

    "cmaj": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 3},
            {finger: 2, string: STR_D, fret: 2},
            {finger: 1, string: STR_B, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "cmin": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_D, fret: 5},
            {finger: 4, string: STR_G, fret: 5},
            {finger: 2, string: STR_B, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 3, from: STR_A, to: STR_e}
        ],
        shift: 2
    },
    // "csus2": {},
    // "csus4": {},
    // "cdim": {},
    // "c+": {},
    "cdom7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 3},
            {finger: 2, string: STR_D, fret: 2},
            {finger: 4, string: STR_G, fret: 3},
            {finger: 1, string: STR_B, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "cmaj7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 3},
            {finger: 2, string: STR_D, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "cm7": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_D, fret: 5},
            {finger: 3, string: STR_G, fret: 5},
            {finger: 1, string: STR_B, fret: 4},
            {finger: 4, string: STR_e, fret: 6},
        ],
        barres: [],
        shift: 3
    },
    // "cmmaj7": {},
    // "cdim7": {},
    // "chdim7": {},

    "dbmaj": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_D, fret: 3},
            {finger: 2, string: STR_B, fret: 2},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_G, to: STR_e}
        ],
        shift: 0
    },
    "dbmin": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_D, fret: 2},
            {finger: 1, string: STR_G, fret: 1},
            {finger: 3, string: STR_B, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    // "dbsus2": {},
    // "dbsus4": {},
    // "dbdim": {},
    // "db+": {},
    "dbdom7": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_e, fret: 7},
        ],
        barres: [
            {finger: 1, fret: 6, from: STR_D, to: STR_B}
        ],
        shift: 5
    },
    "dbmaj7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 4, string: STR_A, fret: 4},
            {finger: 3, string: STR_D, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_G, to: STR_e}
        ],
        shift: 0
    },
    "dbm7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_A, fret: 2},
            {finger: 3, string: STR_D, fret: 2},
            {finger: 1, string: STR_G, fret: 1},
            {finger: 4, string: STR_B, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    // "dbmmaj7": {},
    // "dbdim7": {},
    // "dbhdim7": {},

    "dmaj": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 2},
            {finger: 3, string: STR_B, fret: 3},
            {finger: 2, string: STR_e, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "dmin": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2},
            {finger: 3, string: STR_B, fret: 3},
            {finger: 1, string: STR_e, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    // "dsus2": {},
    // "dsus4": {},
    // "ddim": {},
    // "d+": {},
    "ddom7": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2},
            {finger: 1, string: STR_B, fret: 1},
            {finger: 3, string: STR_e, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "dmaj7": {
        strum: [false, false, true, true, true, true],
        fingers: [],
        barres: [
            {finger: 1, fret: 2, from: STR_G, to: STR_e}
        ],
        shift: 0
    },
    "dm7": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_B, to: STR_e}
        ],
        shift: 0
    },
    // "dmmaj7": {},
    // "ddim7": {},
    // "dhdim7": {},

    "ebmaj": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 1, string: STR_D, fret: 1},
            {finger: 2, string: STR_G, fret: 3},
            {finger: 4, string: STR_B, fret: 4},
            {finger: 3, string: STR_e, fret: 3},
        ],
        barres: [],
        shift: 0
    },
    "ebmin": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_D, fret: 4},
            {finger: 2, string: STR_G, fret: 3},
            {finger: 4, string: STR_B, fret: 4},
            {finger: 1, string: STR_e, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    // "ebsus2": {},
    // "ebsus4": {},
    // "ebdim": {},
    // "eb+": {},
    "ebdom7": {
        strum: [false, true, true, true, true, false],
        fingers: [
            {finger: 3, string: STR_A, fret: 6},
            {finger: 2, string: STR_D, fret: 5},
            {finger: 4, string: STR_G, fret: 6},
            {finger: 1, string: STR_B, fret: 4},
        ],
        barres: [],
        shift: 3
    },
    "ebmaj7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 4, string: STR_A, fret: 6},
            {finger: 3, string: STR_D, fret: 5},
        ],
        barres: [
            {finger: 1, fret: 3, from: STR_G, to: STR_e}
        ],
        shift: 2
    },
    "ebm7": {
        strum: [false, true, true, true, true, false],
        fingers: [
            {finger: 2, string: STR_A, fret: 4},
            {finger: 3, string: STR_D, fret: 4},
            {finger: 1, string: STR_G, fret: 3},
            {finger: 4, string: STR_B, fret: 4},
        ],
        barres: [],
        shift: 2
    },
    // "ebmmaj7": {},
    // "ebdim7": {},
    // "ebhdim7": {},

    "emaj": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_A, fret: 2},
            {finger: 3, string: STR_D, fret: 2},
            {finger: 1, string: STR_G, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "emin": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_A, fret: 2},
            {finger: 3, string: STR_D, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    // "esus2": {},
    // "esus4": {},
    // "edim": {},
    // "e+": {},
    "edom7": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_A, fret: 2},
            {finger: 1, string: STR_G, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "emaj7": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 2},
            {finger: 1, string: STR_D, fret: 1},
            {finger: 2, string: STR_G, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "em7": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_A, fret: 2},
            {finger: 3, string: STR_D, fret: 2},
            {finger: 4, string: STR_B, fret: 3},
        ],
        barres: [],
        shift: 0
    },
    // "emmaj7": {},
    // "edim7": {},
    // "ehdim7": {},

    "fmaj": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 3},
            {finger: 4, string: STR_D, fret: 3},
            {finger: 2, string: STR_G, fret: 2},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_E, to: STR_e}
        ],
        shift: 0
    },
    "fmin": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 3},
            {finger: 4, string: STR_D, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_E, to: STR_e}
        ],
        shift: 0
    },
    // "fsus2": {},
    // "fsus4": {},
    // "fdim": {},
    // "f+": {},
    "fdom7": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 3},
            {finger: 2, string: STR_G, fret: 2},
            {finger: 4, string: STR_B, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_E, to: STR_e}
        ],
        shift: 0
    },
    "fmaj7": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_D, fret: 3},
            {finger: 2, string: STR_G, fret: 2},
            {finger: 1, string: STR_B, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "fm7": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 3},
            {finger: 4, string: STR_B, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_E, to: STR_e}
        ],
        shift: 0
    },
    // "fmmaj7": {},
    // "fdim7": {},
    // "fhdim7": {},

    "f#maj": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 4},
            {finger: 4, string: STR_D, fret: 4},
            {finger: 2, string: STR_G, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_E, to: STR_e}
        ],
        shift: 1
    },
    "f#min": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 4},
            {finger: 4, string: STR_D, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_E, to: STR_e}
        ],
        shift: 1
    },
    // "f#sus2": {},
    // "f#sus4": {},
    // "f#dim": {},
    // "f#+": {},
    "f#dom7": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 4},
            {finger: 2, string: STR_G, fret: 3},
            {finger: 4, string: STR_B, fret: 5},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_E, to: STR_e}
        ],
        shift: 1
    },
    "f#maj7": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 4, string: STR_A, fret: 9},
            {finger: 3, string: STR_D, fret: 8},
        ],
        barres: [
            {finger: 1, fret: 6, from: STR_G, to: STR_e}
        ],
        shift: 5
    },
    "f#m7": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 4},
            {finger: 4, string: STR_B, fret: 5},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_E, to: STR_e}
        ],
        shift: 1
    },
    // "f#mmaj7": {},
    // "f#dim7": {},
    // "f#hdim7": {},

    "gmaj": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_E, fret: 3},
            {finger: 1, string: STR_A, fret: 2},
            {finger: 3, string: STR_e, fret: 3},
        ],
        barres: [],
        shift: 0
    },
    "gmin": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 5},
            {finger: 4, string: STR_D, fret: 5},
        ],
        barres: [
            {finger: 1, fret: 3, from: STR_E, to: STR_e}
        ],
        shift: 2
    },
    // "gsus2": {},
    // "gsus4": {},
    // "gdim": {},
    // "g+": {},
    "gdom7": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_E, fret: 3},
            {finger: 2, string: STR_A, fret: 2},
            {finger: 1, string: STR_e, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "gmaj7": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 4, string: STR_D, fret: 5},
            {finger: 3, string: STR_G, fret: 4},
            {finger: 2, string: STR_B, fret: 3},
            {finger: 1, string: STR_e, fret: 2},
        ],
        barres: [],
        shift: 1
    },
    "gm7": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 5},
            {finger: 4, string: STR_B, fret: 6},
        ],
        barres: [
            {finger: 1, fret: 3, from: STR_E, to: STR_e}
        ],
        shift: 2
    },
    // "gmmaj7": {},
    // "gdim7": {},
    // "ghdim7": {},

    "abmaj": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 4, string: STR_e, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_D, to: STR_B}
        ],
        shift: 0
    },
    "abmin": {
        strum: [true, true, true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 6},
            {finger: 4, string: STR_D, fret: 6},
        ],
        barres: [
            {finger: 1, fret: 4, from: STR_E, to: STR_e}
        ],
        shift: 3
    },
    // "absus2": {},
    // "absus4": {},
    // "abdim": {},
    // "ab+": {},
    "abdom7": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 2, string: STR_e, fret: 2},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_D, to: STR_B}
        ],
        shift: 0
    },
    "abmaj7": {
        strum: [false, true, true, true, true, true],
        fingers: [
            {finger: 4, string: STR_A, fret: 11},
            {finger: 3, string: STR_D, fret: 10},
        ],
        barres: [
            {finger: 1, fret: 8, from: STR_G, to: STR_e}
        ],
        shift: 7
    },
    "abm7": {
        strum: [false, false, true, true, true, true],
        fingers: [
            {finger: 1, string: STR_D, fret: 1},
            {finger: 2, string: STR_G, fret: 1},
            {finger: 4, string: STR_e, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    // "abmmaj7": {},
    // "abdim7": {},
    // "abhdim7": {},
};
