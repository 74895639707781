import React, { useRef, useState } from "react";
import "./stylesheets/Popup.scss";
import PropTypes from "prop-types";
import {usePopper} from "react-popper";

function Popup(props) {
    const [shown, setShown] = useState(false);
    const triggerRef = useRef(null);
    const popperRef = useRef(null);
    const arrowRef = useRef(null);
    const {styles, attributes} = usePopper(triggerRef.current, popperRef.current, {
        modifiers: [
            // { name: "arrow", options: { element: arrowRef.current } },

        ],
        placement: props.position
    });

    return (
        <div className="popup-container">
            <div ref={triggerRef} style={{display: "inline-block"}} onMouseEnter={() => {
                setShown(true);
            }} onMouseLeave={() => {
                setShown(false);
            }}>
                {props.trigger}
            </div>
            <div className="popup-content" ref={popperRef}  style={{
                    ...styles.popper,
                    visibility: shown ? "visible" : "hidden"
                }} {...attributes.popper}>
                <div>
                    {props.content}
                </div>
                <div className="popup-arrow" ref={arrowRef} style={styles.arrow} />
            </div>
        </div>
    );
}

Popup.propTypes = {
    trigger: PropTypes.element.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    position: PropTypes.string
};

Popup.defaultProps = {
    position: "top"
}

export default Popup;