import React from "react";
import PropTypes from "prop-types";
import "./stylesheets/Input.scss";

class Input extends React.Component {
    constructor(props){
        super(props);
    }
    
    render(){
        const sharedProps = {
            className: `custom ${this.props.error ? "error" : ""}`,
            placeholder: this.props.placeholder,
            value: this.props.value,
            autoFocus: this.props.autoFocus,
            onChange: this.props.onChange,
            style: {
                width: "100%",
                ...this.props.style
            }
        };
        return (
            <div>
                {
                    this.props.type == "textarea" ? (
                        <textarea {...sharedProps} />
                    ) : (
                        <input type={this.props.type} {...sharedProps} />
                    )
                }
            </div>
        );
    }
}

Input.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    type: PropTypes.string,
    style: PropTypes.object,
    autoFocus: PropTypes.bool,
    error: PropTypes.bool
};

Input.defaultProps = {
    placeholder: "",
    value: "",
    type: "text"
}

export default Input;