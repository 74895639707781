import React from "react";
import PropTypes from "prop-types";
import SongProfile, { SongProfileTag } from "./SongProfile";
import { Link } from "react-router-dom";
import Button from "./Button";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

class LibraryTagPage extends React.Component {
    render(){
        const {songs, songNamesByTag} = this.props;
        const tag = decodeURIComponent(this.props.match.params.tagId);
        if(!(tag in songNamesByTag)) {
            return (
                <div id="tags-area">
                    <h2>Tag not found</h2>
                    <Link to="/library"><Button content="Back to Library" icon={faArrowRight} iconPosition="right" /></Link>
                </div>
            )
        }
        return (
            <>
                <div id="tags-area">
                    <h2>Viewing tag:</h2>
                    <SongProfileTag tag={tag} /><br />
                    <Link to="/library"><Button content="Back to Library" icon={faArrowRight} iconPosition="right" /></Link>
                </div>
                <div className="profile-grid">
                    {
                        songNamesByTag[tag].map(songId => {
                            const song = songs[songId];
                            return (
                                <SongProfile
                                    song={song}
                                    songs={songs}
                                    songId={songId}
                                    key={songId}
                                    defaultSettings={this.props.defaultSettings}
                                />
                            );
                        })
                    }
                </div>
            </>
        );
    }
}

LibraryTagPage.propTypes = {
    songs: PropTypes.object.isRequired,
    tagNames: PropTypes.array.isRequired,
    songNamesByTag: PropTypes.object.isRequired,
    onDefaultSettingChanged: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired
};

export default LibraryTagPage;