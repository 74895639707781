import React from "react";
import PropTypes from "prop-types";
import SongProfile, { SongProfileTag } from "./SongProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import LibraryFAQ from "./LibraryFAQ";
import LibraryOptionsPanel from "./LibraryOptionsPanel";

class LibraryAllSongs extends React.Component {
    constructor(props){
        super(props);
    }

    render(){
        const {songs, tagNames} = this.props;
        
        const tagsCarouselContent = tagNames.map(tagName => 
            <SongProfileTag tag={tagName} key={tagName} />    
        );

        return (
            <>
                <div id="about-area">
                    <h2>About</h2>
                    <p>
                        Here are some of my favorite songs, their chord progressions, and some short commentary on why I like them.
                        Explore songs by tag or click a song to learn more.
                    </p>
                    
                </div>
                <LibraryFAQ />
                <LibraryOptionsPanel
                    onDefaultSettingChanged={this.props.onDefaultSettingChanged}
                    defaultSettings={this.props.defaultSettings}
                />

                <div id="tags-area">
                    <h2>Explore tags</h2>
                    <div id="tags-carousel">{tagsCarouselContent}</div>
                </div>
                <div className="profile-grid">
                    {
                        Object.keys(songs).map(songId => {
                            const song = songs[songId];
                            return (
                                <SongProfile
                                    song={song}
                                    songId={songId}
                                    songs={songs}
                                    key={songId}
                                    defaultSettings={this.props.defaultSettings}
                                />
                            );
                        })
                    }
                </div>
            </>
        );
    }
}

LibraryAllSongs.propTypes = {
    songs: PropTypes.object.isRequired,
    tagNames: PropTypes.array.isRequired,
    songNamesByTag: PropTypes.object.isRequired,
    onDefaultSettingChanged: PropTypes.func.isRequired,
    defaultSettings: PropTypes.object.isRequired
};

export default LibraryAllSongs;