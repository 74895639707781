import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {easeInOutCubic} from "../constants/consts";
import "./stylesheets/CircleSelector.scss";

class CircleSelector extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            animating: true,
            oldValue: 0,
            animationTime: this.props.animationSpeed,
            animatingLeft: true
        }
        this.interval = null;
    }
    
    componentDidUpdate(prevProps){
        if(this.props.value != prevProps.value){
            this.setState({
                animating: true,
                oldValue: prevProps.value,
                animationTime: 0,
                // animatingLeft: prevProps.value < this.props.value // Due to wrapping around, set from buttons rather than here
            });
            if(this.interval == null){
                this.interval = setInterval(this.animationTick, 20);
            }
        }
    }

    animationTick = () => {
        if(this.state.animationTime > this.props.animationSpeed){
            window.clearInterval(this.interval);
            this.interval = null;
            this.setState({
                animating: false,
                oldValue: this.props.value,
                animationTime: 0
            });
            return;
        }
        this.setState({animationTime: this.state.animationTime + 20});
    }

    render(){
        const selected = this.props.labels[this.props.value];
        const interpolation = easeInOutCubic(this.state.animationTime / this.props.animationSpeed);
        return (
            <div className={`circle-selector ${this.props.disabled ? "disabled" : ""}`}>
                <button onClick={(e) => {
                    if(this.props.disabled) return;
                    this.props.onChange(this.props.value == 0 ? (this.props.labels.length - 1) : (this.props.value - 1));
                    this.setState({animatingLeft: false});
                    e.preventDefault();
                }}>
                    <FontAwesomeIcon icon={faChevronLeft} className="arrow left"  />
                </button>
                <div className="circle-window">
                    <div className="animated" style={{
                        display: this.state.animating ? null : "none",
                        transform: this.state.animating ? `translate(${interpolation * 100 * (this.state.animatingLeft ? -1 : 1)}%)` : ""
                    }}>
                        <p>{this.props.labels[this.state.oldValue]}</p>
                    </div>
                    <div style={{
                        transform: this.state.animating ? `translate(${(1 - interpolation) * 100 * (this.state.animatingLeft ? 1 : -1)}%)` : ""
                    }}>
                        <p>{selected}</p>
                    </div>
                </div>
                <button onClick={(e) => {
                    if (this.props.disabled) return;
                    this.props.onChange((this.props.value + 1) % this.props.labels.length);
                    this.setState({animatingLeft: true});
                    e.preventDefault();
                }}>
                    <FontAwesomeIcon icon={faChevronRight} className="arrow right" />
                </button>
            </div>
        );
    }
}

CircleSelector.propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    animationSpeed: PropTypes.number,
    disabled: PropTypes.bool
};

CircleSelector.defaultProps = {
    animationSpeed: 200
}

export default CircleSelector;