import {DO, DI, RAH, RE, RI, ME, MI, FA, FI, SE, SOL, SI, LE, LA, LI, TE, TI} from "./solfege";
import * as PersonalityImages from "../resources/chord-personalities";

export function getNotesInChord(chord) {
    const qualityInfo = CHORD_QUALITIES[chord.quality];
    const notes = [...qualityInfo.notes];
    for(const alteration of chord.alterations) {
        const i = notes.indexOf(alteration.note);
        if(i == -1) continue;
        notes[i] = alteration.note + alteration.delta;
    }
    for(const addition of chord.additions) {
        notes.push(addition.note);
    }
    for(const omission of chord.omissions) {
        const i = notes.indexOf(omission.note);
        notes.splice(i, 1);
    }
    return notes;
}

// Keys must not be parseable: https://stackoverflow.com/questions/5525795/does-javascript-guarantee-object-property-order
export const CHORD_QUALITIES = {
    "maj": {
        displayName: "Major",
        notes: [DO, MI, SOL],
        aliases: ["", "maj"],
        capitalize: true,
        simplifiesTo: "maj",
        color: {
            saturation: 1,
            lightness: .75
        },
        image: PersonalityImages.Blank
    },
    "min": {
        displayName: "Minor",
        notes: [DO, ME, SOL],
        aliases: ["m", "min"],
        capitalize: false,
        simplifiesTo: "min",
        color: {
            saturation: .33,
            lightness: .38
        },
        image: PersonalityImages.Blank
    },
    "sus2": {
        displayName: "Suspended 2nd",
        notes: [DO, RE, SOL],
        aliases: ["sus2"],
        capitalize: true,
        simplifiesTo: "maj",
        color: {
            saturation: .43,
            lightness: .56
        },
        image: PersonalityImages.Target
    },
    "sus4": {
        displayName: "Suspended 4th",
        notes: [DO, FA, SOL],
        aliases: ["sus4", "sus"],
        capitalize: true,
        simplifiesTo: "maj",
        color: {
            saturation: .43,
            lightness: .56
        },
        image: PersonalityImages.Target
    },
    "dim": {
        displayName: "Diminished",
        notes: [DO, ME, SE],
        aliases: ["dim", "°"],
        capitalize: false,
        simplifiesTo: "min",
        color: {
            saturation: .33,
            lightness: .38
        },
        image: PersonalityImages.Rainfall
    },
    "+": {
        displayName: "Augmented",
        notes: [DO, MI, SI],
        aliases: ["+", "aug"],
        capitalize: true,
        simplifiesTo: "maj",
        color: {
            saturation: 1,
            lightness: .75
        },
        image: PersonalityImages.Cations
    },
    "pwr5": {
        displayName: "5th",
        notes: [DO, SOL],
        aliases: ["5"],
        capitalize: true,
        simplifiesTo: "maj",
        color: {
            saturation: 1,
            lightness: .75
        },
        image: PersonalityImages.Bolts
    },
    "maj6": {
        displayName: "Major 6th",
        notes: [DO, MI, SOL, LA],
        aliases: ["6"],
        capitalize: true,
        simplifiesTo: "maj",
        color: {
            saturation: 1,
            lightness: .75
        },
        image: PersonalityImages.Stars
    },
    "m6": {
        displayName: "Minor 6th",
        notes: [DO, ME, SOL, SI],
        aliases: ["m6"],
        capitalize: false,
        simplifiesTo: "min",
        color: {
            saturation: .33,
            lightness: .38
        },
        image: PersonalityImages.Moon
    },
    "dom7": {
        displayName: "Dominant 7th",
        notes: [DO, MI, SOL, TE],
        aliases: ["7"],
        capitalize: true,
        simplifiesTo: "maj",
        color: {
            saturation: 1,
            lightness: .75
        },
        image: PersonalityImages.Stripes
    },
    "maj7": {
        displayName: "Major 7th",
        notes: [DO, MI, SOL, TI],
        aliases: ["maj7", "△"],
        capitalize: true,
        simplifiesTo: "maj",
        color: {
            saturation: 1,
            lightness: .75
        },
        image: PersonalityImages.Stars
    },
    "m7": {
        displayName: "Minor 7th",
        notes: [DO, ME, SOL, TE],
        aliases: ["m7", "min7", "-", "-7"],
        capitalize: false,
        simplifiesTo: "min",
        color: {
            saturation: .33,
            lightness: .38
        },
        image: PersonalityImages.Moon
    },
    "mmaj7": {
        displayName: "Minor-Major 7th",
        notes: [DO, ME, SOL, TI],
        aliases: ["mmaj7"],
        capitalize: false,
        simplifiesTo: "maj",
        color: {
            saturation: .33,
            lightness: .38
        },
        image: PersonalityImages.Moon
    },
    "+7": {
        displayName: "Augmented Dominant 7th",
        notes: [DO, ME, SI, TE],
        aliases: ["+7", "aug7"],
        capitalize: true,
        simplifiesTo: "maj",
        color: {
            saturation: .1,
            lightness: .75
        },
        image: PersonalityImages.Cations
    },
    "augmaj7": {
        displayName: "Augmented Major 7th",
        notes: [DO, ME, SI, TI],
        aliases: ["augmaj7"],
        capitalize: true,
        simplifiesTo: "maj",
        color: {
            saturation: 1,
            lightness: .75
        },
        image: PersonalityImages.Cations
    },
    "dim7": {
        displayName: "Diminished 7th",
        notes: [DO, ME, SE, LA],
        aliases: ["dim7", "°7"],
        capitalize: false,
        simplifiesTo: "min",
        color: {
            saturation: .33,
            lightness: .38
        },
        image: PersonalityImages.Rainfall
    },
    "hdim7": {
        displayName: "Half-Diminished 7th",
        notes: [DO, ME, SE, TE],
        aliases: ["ø", "ø7", "hdim", "hdim7", "m7b5", "min7b5"],
        capitalize: false,
        simplifiesTo: "min",
        color: {
            saturation: .33,
            lightness: .38
        },
        image: PersonalityImages.Rainfall
    },
};

export const EXTENDED_CHORD_BASE_QUALITIES = {
    "dom7": "",
    "maj7": "maj",
    "m7": "m",
    "mmaj7": "mmaj",
    "augmaj7": "augmaj",
    "+7": "+",
    "hdim7": "ø",
    "dim7": "dim"
};
