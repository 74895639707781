import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./stylesheets/Button.scss";

class Button extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        const iconOnly = this.props.content.length == 0;
        const icon = this.props.icon == null ? null : (
            <FontAwesomeIcon icon={this.props.icon} style={{
                marginLeft: (this.props.iconPosition == "right" && !iconOnly) ? "10px" : 0,
                marginRight: (this.props.iconPosition == "left" && !iconOnly) ? "10px" : 0
            }} />
        );
        return (
            <button className={`custom ${this.props.primary ? "primary" : ""} ${this.props.subtle ? "subtle" : ""} ${this.props.jumbo ? "jumbo" : ""} ${this.props.disabled ? "disabled" : ""} ${iconOnly ? "icon-only" : ""}`} style={this.props.style} onClick={() => {
                if(this.props.onClick != null){
                    this.props.onClick();
                }
            }}>
                {this.props.iconPosition == "left" ? icon : null}
                {this.props.content}
                {this.props.iconPosition == "right" ? icon : null}
            </button>
        );
    }
}

Button.propTypes = {
    content: PropTypes.string,
    primary: PropTypes.bool,
    subtle: PropTypes.bool,
    jumbo: PropTypes.bool,
    disabled: PropTypes.bool,
    icon: PropTypes.object,
    iconPosition: PropTypes.oneOf(["left", "right"]),
    onClick: PropTypes.func
};

Button.defaultProps = {
    content: "",
    iconPosition: "left"
}

export default Button;