import React from "react";
import PropTypes from "prop-types";
import Dropdown from "./Dropdown";
import INSTRUMENTS from "../constants/instruments";

function InstrumentDropdown(props) {
    return (
        <Dropdown
            value={props.value}
            options={Object.keys(INSTRUMENTS).map(i => ({key: i, text: INSTRUMENTS[i].displayName}))}
            placeholder="Select an instrument"
            onChange={props.onChange}
            disabled={props.disabled}
        />
    );
}

InstrumentDropdown.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

export default InstrumentDropdown;