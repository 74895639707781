import React from "react";
import PropTypes from "prop-types";
import INSTRUMENTS from "../constants/instruments";
import "./stylesheets/ChordDiagram.scss";

class ChordDiagram extends React.Component {
    render(){
        let instrument, error, chord;
        if(this.props.instrument in INSTRUMENTS){
            instrument = INSTRUMENTS[this.props.instrument];
            if(this.props.chord.toLowerCase() in instrument.chordDiagrams){
                chord = instrument.chordDiagrams[this.props.chord.toLowerCase()];
            } else {
                // console.error("Invalid chord value: " + this.props.chord);
                error = true;
            }
        } else {
            console.error("Invalid instrument value: " + this.props.instrument);
            error = true;
        }

        const POINT_RADIUS = 13 * this.props.scale;
        const MARGIN_TOP = 30 * this.props.scale;
        const STRING_INDICATOR_RADIUS = 7 * this.props.scale;
        const STRING_INDICATOR_STROKE_WEIGHT = 3 * this.props.scale;
        const TEXT_SIZE = 20 * this.props.scale;
        const STRING_LINE_WEIGHT = 3 * this.props.scale;
        const FRET_LINE_WEIGHT = 3 * this.props.scale;
        const TOP_LINE_STROKE_WEIGHT = 7 * this.props.scale;
        const MARGIN_RIGHT = 20 * this.props.scale;
        const FR_TEXT_SIZE = 40 * this.props.scale;
        const {lineColor, foregroundColor, textColor, errorColor} = this.props;
        const TEXT_FAMILY = "Mulish";
        const TEXT_WEIGHT = "bold";

        if(error){
            return (
                <div style={{
                    width: this.props.width,
                    ...this.props.style
                }} className="chord-diagram">
                    <div className="aspect-ratio" style={{
                        paddingTop: `${instrument.diagramAspectRatio * 100}%`
                    }} />
                    <svg xmlns="http://www.w3.org/2000/svg" style={{
                        overflow: "visible",
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        width: "100%",
                        height: "100%",
                        paddingTop: `${MARGIN_TOP}px`,
                        paddingLeft: `${POINT_RADIUS}px`,
                        paddingRight: `${POINT_RADIUS}px`,
                        boxSizing: "border-box"
                    }}>
                        <svg style={{ overflow: "visible" }}>
                            <svg style={{
                                position: "absolute",
                                left: `-${STRING_LINE_WEIGHT / 2}px`,
                                right: `-${STRING_LINE_WEIGHT / 2}px`,
                                top: 0,
                                bottom: 0
                            }}>
                                <line
                                    x1="0%"
                                    x2="100%"
                                    y1="0%"
                                    y2="0%"
                                    stroke={errorColor}
                                    strokeWidth={TOP_LINE_STROKE_WEIGHT}
                                />
                            </svg>
                            {/* Border strings */}
                            <line x1="0%" x2="0%" y1="0%" y2="100%" stroke={errorColor} strokeWidth={STRING_LINE_WEIGHT} />
                            <line x1="100%" x2="100%" y1="0%" y2="100%" stroke={errorColor} strokeWidth={STRING_LINE_WEIGHT} />
                            {/* Bottom fret */}
                            <line x1="0%" x2="100%" y1="100%" y2="100%" stroke={errorColor} strokeWidth={FRET_LINE_WEIGHT} />
                            {/* X symbol */}
                            <line
                                x1="33.3%"
                                x2="66.6%"
                                y1="33.3%"
                                y2="66.6%"
                                stroke={errorColor}
                                strokeWidth={TOP_LINE_STROKE_WEIGHT}
                                strokeLinecap="round"
                            />
                            <line
                                x1="33.3%"
                                x2="66.6%"
                                y1="66.6%"
                                y2="33.3%"
                                stroke={errorColor}
                                strokeWidth={TOP_LINE_STROKE_WEIGHT}
                                strokeLinecap="round"
                            />
                        </svg>
                        
                    </svg>
                </div>
            );
        }

        const strings = [];
        const frets = [];
        for(let i = 0; i < instrument.numStrings; i++){
            const pos = i / (instrument.numStrings - 1) * 100
            const isOpenString = !chord.fingers.some(f => f.string == i) && !chord.barres.some(b => b.from <= i && b.to >= i);
            strings.push(
                <React.Fragment key={i}>
                    {
                        chord.strum[i] ? (
                            isOpenString ? (
                                <circle
                                    cx={`${pos}%`}
                                    cy={-MARGIN_TOP + STRING_INDICATOR_RADIUS + (3/2)}
                                    r={STRING_INDICATOR_RADIUS}
                                    fill="none"
                                    strokeWidth={STRING_INDICATOR_STROKE_WEIGHT}
                                    stroke={lineColor}
                                    key={`stringcircle-${i}`}
                                />
                            ) : null
                        ) : (
                            // X symbol
                            <svg
                                x={`${pos}%`}
                                y={-MARGIN_TOP + 3/2}
                                width={0}
                                height={STRING_INDICATOR_RADIUS * 2}
                                style={{ overflow: "visible" }}
                            >
                                <line
                                    x1={-STRING_INDICATOR_RADIUS}
                                    x2={STRING_INDICATOR_RADIUS}
                                    y1="0%"
                                    y2="100%"
                                    stroke={lineColor}
                                    strokeWidth={STRING_INDICATOR_STROKE_WEIGHT}
                                    strokeLinecap="round"
                                    key={`stringx1-${i}`}
                                />
                                <line
                                    x1={-STRING_INDICATOR_RADIUS}
                                    x2={STRING_INDICATOR_RADIUS}
                                    y1="100%"
                                    y2="0%"
                                    stroke={lineColor}
                                    strokeWidth={STRING_INDICATOR_STROKE_WEIGHT}
                                    strokeLinecap="round"
                                    key={`stringx2-${i}`}
                                />
                            </svg>
                        )
                    }
                    <line x1={`${pos}%`} x2={`${pos}%`} y1="0%" y2="100%" stroke={lineColor} strokeWidth={STRING_LINE_WEIGHT} key={`stringline-${i}`} />
                </React.Fragment>
            );
        }
        for(let i = 1; i <= instrument.numFretsInDiagram; i++){
            const linePos = `${(i) / instrument.numFretsInDiagram * 100}%`;
            frets.push(
                <line x1="0%" x2="100%" y1={linePos} y2={linePos} stroke={lineColor} strokeWidth={FRET_LINE_WEIGHT} key={`fretline-${i}`} />
            );
        }
        const fingers = chord.fingers.map((finger, idx) => {
            const xPos = `${finger.string / (instrument.numStrings - 1) * 100}%`;
            const yPos = `${(finger.fret - chord.shift - 0.5) / instrument.numFretsInDiagram * 100}%`;
            return (
                <React.Fragment key={idx}>
                    <circle cx={xPos} cy={yPos} r={POINT_RADIUS} fill={foregroundColor} key={`finger-circle-${idx}`} />
                    <text
                        x={xPos}
                        y={yPos}
                        fill={textColor}
                        textAnchor="middle"
                        dominantBaseline="central"
                        fontSize={TEXT_SIZE}
                        fontFamily={TEXT_FAMILY}
                        fontWeight={TEXT_WEIGHT}
                        key={`finger-text-${idx}`}
                    >
                        {finger.finger}
                    </text>
                </React.Fragment>
            );
        });
        const barres = chord.barres.map((barre, idx) => {
            const fromPos = `${barre.from / (instrument.numStrings - 1) * 100}%`;
            const toPos = `${barre.to / (instrument.numStrings - 1) * 100}%`;
            const yPos = `${(barre.fret - chord.shift - 0.5) / instrument.numFretsInDiagram * 100}%`;
            return (
                <line x1={fromPos} x2={toPos} y1={yPos} y2={yPos} stroke={foregroundColor} strokeWidth={POINT_RADIUS * 2} strokeLinecap="round" key={`barre-${idx}`} />
            );
        });
        return (
            <div style={{
                width: this.props.width,
                ...this.props.style
            }} className="chord-diagram">
                <div className="aspect-ratio" style={{
                    paddingTop: `${instrument.diagramAspectRatio * 100}%`
                }} /> {/* Aspect ratio hack (see https://stackoverflow.com/questions/1495407/maintain-the-aspect-ratio-of-a-div-with-css) */}
                <svg xmlns="http://www.w3.org/2000/svg" style={{
                    overflow: "visible",
                    position: "absolute",
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: "100%",
                    height: "100%",
                    paddingTop: `${MARGIN_TOP}px`,
                    paddingLeft: `${POINT_RADIUS}px`,
                    paddingRight: `${POINT_RADIUS}px`,
                    boxSizing: "border-box"
                }}>
                    <svg style={{ overflow: "visible" }}>
                        <svg style={{
                            position: "absolute",
                            left: `-${STRING_LINE_WEIGHT / 2}px`,
                            right: `-${STRING_LINE_WEIGHT / 2}px`,
                            top: 0,
                            bottom: 0
                        }}>
                            <line
                                x1="0%"
                                x2="100%"
                                y1="0%"
                                y2="0%"
                                stroke={lineColor}
                                strokeWidth={TOP_LINE_STROKE_WEIGHT}
                            />
                        </svg>
                        {strings}
                        {frets}
                        {fingers}
                        {barres}
                    </svg>
                    {
                        chord.shift > 0 ? (
                            <text
                                x="110%"
                                y={`${MARGIN_TOP}px`}
                                fill={lineColor}
                                textAnchor="start"
                                alignmentBaseline="middle"
                                fontSize={FR_TEXT_SIZE}
                                fontFamily={TEXT_FAMILY}
                                fontWeight={TEXT_WEIGHT}
                            >
                                {chord.shift + 1}fr
                            </text>
                        ) : null
                    }
                </svg>
            </div>
        );
    }
}

ChordDiagram.propTypes = {
    width: PropTypes.string,
    style: PropTypes.object,
    instrument: PropTypes.string.isRequired,
    chord: PropTypes.string.isRequired,
    scale: PropTypes.number,
    lineColor: PropTypes.string,
    foregroundColor: PropTypes.string,
    textColor: PropTypes.string
};
ChordDiagram.defaultProps = {
    width: "100%",
    style: {},
    scale: 1,
    lineColor: "#ffffff",
    foregroundColor: "#8674ff",
    textColor: "#000000",
    errorColor: "#7a7a7a"
};

export default ChordDiagram;
