import React from "react";

export default class NotFoundPage extends React.Component {
    render(){
        return (
            <div style={{textAlign: "center"}}>
                <h1>Page not found...</h1>
            </div>
        );
    }
}