import React from "react";
import "./stylesheets/ChordTabPage.scss";
import { parseQuickInput } from "../constants/chord-serializer";
import {DO, DI, RAH, RE, RI, ME, MI, FA, FI, SE, SOL, SI, LE, LA, LI, TE, TI} from "../constants/solfege";
import { getPitchDisplayName, PITCH_NAMES, PITCH_NAMES_LIST } from "../constants/note-definitions";
import INSTRUMENTS from "../constants/instruments";
import Input from "../components/Input";
import InstrumentDropdown from "../components/InstrumentDropdown";
import CircleSelector from "../components/CircleSelector";
import ChordOverview from "../components/ChordOverview";
import { addParamToURL } from "../constants/consts";
import ProgressionPlayer from "../components/ProgressionPlayer";
import Button from "../components/Button";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import TabsFAQ from "../components/TabsFAQ";

export default class ChordTabPage extends React.Component {
    constructor(props){
        super(props);
        const urlParams = new URLSearchParams(props.location.search);
        this.state = {
            instrument: urlParams.has("instrument") && (urlParams.get("instrument") in INSTRUMENTS) ? urlParams.get("instrument") : Object.keys(INSTRUMENTS)[0],
            sequence: [], // a sequence of chord objects.
            key: urlParams.has("key") && (urlParams.get("key") in PITCH_NAMES) ? PITCH_NAMES[urlParams.get("key")] : PITCH_NAMES["c"],
            tempo: 120,
            quickInputValue: urlParams.has("v") ? urlParams.get("v") : "",
            firstError: null,
            errorChord: null,
            showProgressionPlayer: false
        };
        if(this.state.quickInputValue.length > 0){
            this.state = {
                ...this.state,
                ...parseQuickInput(this.state.quickInputValue, this.state.key)
            };
        }
    }

    onPlayButtonPress = () => {
        if(this.state.firstError || this.state.sequence.length === 0) return;
        this.setState({showProgressionPlayer: true});
    }

    onProgressionPlayerClose = () => {
        this.setState({showProgressionPlayer: false});
    }

    onTempoChange = (e) => {
        let val = parseInt(e.target.value);
        if(isNaN(val)) return;
        if(val < 1) val = 1;
        if(val > 200) val = 200;
        this.setState({ tempo: val });
    }
    
    render(){
        return (
            <div id="chord-tab-main-content">
                <ProgressionPlayer
                    visible={this.state.showProgressionPlayer}
                    keyNum={this.state.key}
                    instrument={this.state.instrument}
                    onClose={this.onProgressionPlayerClose}
                    sequence={this.state.sequence}
                    tempo={this.state.tempo}
                />
                <div className="rails">
                    <h2 className="subtitle centered gradient-text">Tab Generator</h2>
                    <TabsFAQ />
                    <Input error={this.state.firstError != null} autoFocus value={this.state.quickInputValue} placeholder="iim7 G7/D Cmaj7" onChange={e => {
                        this.setState({quickInputValue: e.target.value, ...parseQuickInput(e.target.value, this.state.key)});
                        addParamToURL("v", e.target.value, this.props.history, this.props.location);
                    }} style={{
                        width: "100%",
                        fontSize: "30px"
                    }} />
                    <p className="centered error" id="quick-input-error">{
                        this.state.firstError && `Error parsing "${this.state.errorChord}": ${this.state.firstError.message}`
                    }</p>
                    <div className="options-panel">
                        <div>
                            <p>Instrument</p>
                            <InstrumentDropdown value={this.state.instrument} onChange={val => {
                                addParamToURL("instrument", val, this.props.history, this.props.location);
                                this.setState({instrument: val});
                            }} />
                        </div>
                        <div>
                            <p>Key</p>
                            <CircleSelector labels={
                                PITCH_NAMES_LIST.map(i => getPitchDisplayName(i).toUpperCase())
                            } value={this.state.key} onChange={(val) => {
                                addParamToURL("key", PITCH_NAMES_LIST[val], this.props.history, this.props.location);
                                this.setState({key: val, ...parseQuickInput(this.state.quickInputValue, val)});
                                // TODO: Transpose absolute chords
                            }} />
                        </div>
                        <div>
                            <p>Tempo</p>
                            <Input
                                value={this.state.tempo.toString()}
                                type="number"
                                onChange={this.onTempoChange}
                                style={{
                                    width: "50px"
                                }}
                            />
                        </div>
                        <div>
                            <p>Play</p>
                            <Button
                                disabled={this.state.firstError != null || this.state.sequence.length === 0}
                                content="Play"
                                icon={faPlay}
                                onClick={this.onPlayButtonPress}
                            />
                        </div>
                    </div>
                    <ChordOverview
                        sequence={this.state.sequence}
                        keyNum={this.state.key}
                        instrument={this.state.instrument}
                    />
                </div>
            </div>
        );
    }
}