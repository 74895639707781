const STR_G = 0;
const STR_C = 1;
const STR_E = 2;
const STR_A = 3;

/*
{
    strum: [true, true, true, true],
    fingers: [
        {finger: 1, string: STR_G, fret: 1},
        {finger: 1, string: STR_C, fret: 1},
        {finger: 1, string: STR_E, fret: 1},
        {finger: 1, string: STR_A, fret: 1},
    ],
    barres: [],
    shift: 0
}
*/


// Frets are 1-based index but strings are 0-based. Fingers are 1-based
// Shift: will shift diagram by however many frets. 0 for no shift; for example, for 3fr, do shift: 2
export const UKULELE_CHORD_DIAGRAMS = {
    "amaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2},
            {finger: 1, string: STR_C, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "amin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2}
        ],
        barres: [],
        shift: 0
    },
    // "asus2": {},
    // "asus4": {},
    "adim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 2},
            {finger: 2, string: STR_C, fret: 3},
            {finger: 4, string: STR_E, fret: 5},
            {finger: 3, string: STR_A, fret: 3},
        ],
        barres: [],
        shift: 1
    },
    "a+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_G, fret: 2},
            {finger: 1, string: STR_C, fret: 1},
            {finger: 2, string: STR_E, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "adom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_C, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "amaj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 2, string: STR_C, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "am7": {
        strum: [true, true, true, true],
        fingers: [],
        barres: [],
        shift: 0
    },
    // "ammaj7": {},
    // "adim7": {},
    // "ahdim7": {},

    "bbmaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_G, fret: 3},
            {finger: 2, string: STR_C, fret: 2},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_E, to: STR_A}
        ],
        shift: 0
    },
    "bbmin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_G, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_C, to: STR_A}
        ],
        shift: 0
    },
    // "bbsus2": {},
    // "bbsus4": {},
    "bbdim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 3},
            {finger: 2, string: STR_C, fret: 4},
            {finger: 4, string: STR_E, fret: 6},
            {finger: 3, string: STR_A, fret: 4},
        ],
        barres: [],
        shift: 2
    },
    "bb+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 3},
            {finger: 2, string: STR_C, fret: 2},
            {finger: 3, string: STR_E, fret: 2},
            {finger: 1, string: STR_A, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "bbdom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_C, fret: 2},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_G, to: STR_A}
        ],
        shift: 0
    },
    "bbmaj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_G, fret: 3},
            {finger: 2, string: STR_C, fret: 2},
            {finger: 1, string: STR_E, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "bbm7": {
        strum: [true, true, true, true],
        fingers: [],
        barres: [
            {finger: 1, fret: 1, from: STR_G, to: STR_A}
        ],
        shift: 0
    },
    // "bbmmaj7": {},
    // "bbdim7": {},
    // "bbhdim7": {},

    "bmaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_G, fret: 4},
            {finger: 2, string: STR_C, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_E, to: STR_A}
        ],
        shift: 0
    },
    "bmin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_C, to: STR_A}
        ],
        shift: 0
    },
    // "bsus2": {},
    // "bsus4": {},
    "bdim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 4},
            {finger: 2, string: STR_C, fret: 2},
            {finger: 1, string: STR_E, fret: 1},
            {finger: 3, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "b+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 4},
            {finger: 2, string: STR_C, fret: 3},
            {finger: 3, string: STR_E, fret: 3},
            {finger: 1, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "bdom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_G, fret: 4},
            {finger: 2, string: STR_C, fret: 3},
            {finger: 1, string: STR_E, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "bmaj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 4},
            {finger: 3, string: STR_C, fret: 3},
            {finger: 2, string: STR_E, fret: 2},
            {finger: 1, string: STR_A, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "bm7": {
        strum: [true, true, true, true],
        fingers: [],
        barres: [
            {finger: 1, fret: 2, from: STR_G, to: STR_A}
        ],
        shift: 0
    },
    // "bmmaj7": {},
    // "bdim7": {},
    // "bhdim7": {},

    "cmaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 3}
        ],
        barres: [],
        shift: 0
    },
    "cmin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, fret: 5, string: STR_G}
        ],
        barres: [
            {finger: 1, fret: 3, from: STR_C, to: STR_A}
        ],
        shift: 2
    },
    // "csus2": {},
    // "csus4": {},
    "cdim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 5},
            {finger: 2, string: STR_C, fret: 3},
            {finger: 1, string: STR_E, fret: 2},
            {finger: 3, string: STR_A, fret: 3},
        ],
        barres: [],
        shift: 1
    },
    "c+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 5},
            {finger: 2, string: STR_C, fret: 4},
            {finger: 3, string: STR_E, fret: 4},
            {finger: 1, string: STR_A, fret: 3},
        ],
        barres: [],
        shift: 2
    },
    "cdom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_A, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "cmaj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "cm7": {
        strum: [true, true, true, true],
        fingers: [],
        barres: [
            {finger: 1, fret: 3, from: STR_G, to: STR_A}
        ],
        shift: 0
    },
    // "cmmaj7": {},
    // "cdim7": {},
    // "chdim7": {},

    "dbmaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_A, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_G, to: STR_E}
        ],
        shift: 0
    },
    "dbmin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 2, string: STR_C, fret: 1},
            {finger: 4, string: STR_A, fret: 4},
        ],
        barres: [],
        shift: 0
    },
    // "dbsus2": {},
    // "dbsus4": {},
    "dbdim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 6},
            {finger: 2, string: STR_C, fret: 4},
            {finger: 1, string: STR_E, fret: 3},
            {finger: 3, string: STR_A, fret: 4},
        ],
        barres: [],
        shift: 2
    },
    "db+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 6},
            {finger: 2, string: STR_C, fret: 5},
            {finger: 3, string: STR_E, fret: 5},
            {finger: 1, string: STR_A, fret: 4},
        ],
        barres: [],
        shift: 3
    },
    "dbdom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_A, fret: 2},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_G, to: STR_E}
        ],
        shift: 0
    },
    "dbmaj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_G, to: STR_E}
        ],
        shift: 0
    },
    "dbm7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 2, string: STR_C, fret: 1},
            {finger: 4, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    // "dbmmaj7": {},
    // "dbdim7": {},
    // "dbhdim7": {},

    "dmaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 2},
            {finger: 2, string: STR_C, fret: 2},
            {finger: 3, string: STR_E, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "dmin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2},
            {finger: 3, string: STR_C, fret: 2},
            {finger: 1, string: STR_E, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    // "dsus2": {},
    // "dsus4": {},
    "ddim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 7},
            {finger: 2, string: STR_C, fret: 5},
            {finger: 1, string: STR_E, fret: 4},
            {finger: 3, string: STR_A, fret: 5},
        ],
        barres: [],
        shift: 3
    },
    "d+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 7},
            {finger: 2, string: STR_C, fret: 6},
            {finger: 3, string: STR_E, fret: 6},
            {finger: 1, string: STR_A, fret: 5},
        ],
        barres: [],
        shift: 4
    },
    "ddom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_A, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_G, to: STR_E}
        ],
        shift: 0
    },
    "dmaj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_G, to: STR_E}
        ],
        shift: 0
    },
    "dm7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2},
            {finger: 3, string: STR_C, fret: 2},
            {finger: 1, string: STR_E, fret: 1},
            {finger: 4, string: STR_A, fret: 3},
        ],
        barres: [],
        shift: 0
    },
    // "dmmaj7": {},
    // "ddim7": {},
    // "dhdim7": {},

    "ebmaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 3},
            {finger: 3, string: STR_C, fret: 3},
            {finger: 4, string: STR_E, fret: 3},
            {finger: 1, string: STR_A, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "ebmin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_G, fret: 3},
            {finger: 4, string: STR_C, fret: 3},
            {finger: 2, string: STR_E, fret: 2},
            {finger: 1, string: STR_A, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    // "ebsus2": {},
    // "ebsus4": {},
    "ebdim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 2},
            {finger: 3, string: STR_C, fret: 3},
            {finger: 2, string: STR_E, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "eb+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 8},
            {finger: 2, string: STR_C, fret: 7},
            {finger: 3, string: STR_E, fret: 7},
            {finger: 1, string: STR_A, fret: 6},
        ],
        barres: [],
        shift: 5
    },
    "ebdom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_A, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 3, from: STR_G, to: STR_E}
        ],
        shift: 2
    },
    "ebmaj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 5},
        ],
        barres: [
            {finger: 1, fret: 3, from: STR_G, to: STR_E}
        ],
        shift: 2
    },
    "ebm7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 3},
            {finger: 3, string: STR_C, fret: 3},
            {finger: 1, string: STR_E, fret: 2},
            {finger: 4, string: STR_A, fret: 4},
        ],
        barres: [],
        shift: 0
    },
    // "ebmmaj7": {},
    // "ebdim7": {},
    // "ebhdim7": {},

    "emaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 4},
            {finger: 3, string: STR_C, fret: 4},
            {finger: 4, string: STR_E, fret: 4},
            {finger: 1, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "emin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_C, fret: 4},
            {finger: 2, string: STR_E, fret: 3},
            {finger: 1, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    // "esus2": {},
    // "esus4": {},
    "edim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 3},
            {finger: 4, string: STR_C, fret: 4},
            {finger: 3, string: STR_E, fret: 3},
            {finger: 1, string: STR_A, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "e+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 9},
            {finger: 2, string: STR_C, fret: 8},
            {finger: 3, string: STR_E, fret: 8},
            {finger: 1, string: STR_A, fret: 7},
        ],
        barres: [],
        shift: 6
    },
    "edom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 2, string: STR_C, fret: 2},
            {finger: 4, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "emaj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 6},
        ],
        barres: [
            {finger: 1, fret: 4, from: STR_G, to: STR_E}
        ],
        shift: 3
    },
    "em7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 4},
            {finger: 3, string: STR_C, fret: 4},
            {finger: 1, string: STR_E, fret: 3},
            {finger: 4, string: STR_A, fret: 5},
        ],
        barres: [],
        shift: 2
    },
    // "emmaj7": {},
    // "edim7": {},
    // "ehdim7": {},

    "fmaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2},
            {finger: 1, string: STR_E, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "fmin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 2, string: STR_E, fret: 1},
            {finger: 4, string: STR_A, fret: 3},
        ],
        barres: [],
        shift: 0
    },
    // "fsus2": {},
    // "fsus4": {},
    "fdim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 4},
            {finger: 4, string: STR_C, fret: 5},
            {finger: 3, string: STR_E, fret: 4},
            {finger: 1, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 1
    },
    "f+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_G, fret: 2},
            {finger: 1, string: STR_C, fret: 1},
            {finger: 2, string: STR_E, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "fdom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2},
            {finger: 3, string: STR_C, fret: 3},
            {finger: 1, string: STR_E, fret: 1},
            {finger: 4, string: STR_A, fret: 3},
        ],
        barres: [],
        shift: 0
    },
    "fmaj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 7},
        ],
        barres: [
            {finger: 1, fret: 5, from: STR_G, to: STR_E}
        ],
        shift: 4
    },
    "fm7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_C, fret: 3},
            {finger: 4, string: STR_A, fret: 3},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_G, to: STR_E}
        ],
        shift: 0
    },
    // "fmmaj7": {},
    // "fdim7": {},
    // "fhdim7": {},

    "f#maj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_G, fret: 3},
            {finger: 2, string: STR_E, fret: 2},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_C, to: STR_A}
        ],
        shift: 0
    },
    "f#min": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2},
            {finger: 1, string: STR_C, fret: 1},
            {finger: 3, string: STR_E, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    // "f#sus2": {},
    // "f#sus4": {},
    "f#dim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 2},
            {finger: 3, string: STR_E, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "f#+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 4, string: STR_G, fret: 3},
            {finger: 2, string: STR_C, fret: 2},
            {finger: 3, string: STR_E, fret: 2},
            {finger: 1, string: STR_A, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "f#dom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_G, fret: 3},
            {finger: 3, string: STR_C, fret: 4},
            {finger: 1, string: STR_E, fret: 2},
            {finger: 2, string: STR_A, fret: 4},
        ],
        barres: [],
        shift: 0
    },
    "f#maj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_A, fret: 8},
        ],
        barres: [
            {finger: 1, fret: 6, from: STR_G, to: STR_E}
        ],
        shift: 5
    },
    "f#m7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 3, string: STR_C, fret: 4},
            {finger: 4, string: STR_A, fret: 4},
        ],
        barres: [
            {finger: 1, fret: 2, from: STR_G, to: STR_E}
        ],
        shift: 0
    },
    // "f#mmaj7": {},
    // "f#dim7": {},
    // "f#hdim7": {},

    "gmaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_C, fret: 2},
            {finger: 3, string: STR_E, fret: 3},
            {finger: 2, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "gmin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_C, fret: 2},
            {finger: 4, string: STR_E, fret: 3},
            {finger: 1, string: STR_A, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    // "gsus2": {},
    // "gsus4": {},
    "gdim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_C, fret: 1},
            {finger: 4, string: STR_E, fret: 3},
            {finger: 2, string: STR_A, fret: 1},
        ],
        barres: [],
        shift: 0
    },
    "g+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_C, fret: 3},
            {finger: 3, string: STR_E, fret: 3},
            {finger: 1, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "gdom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_C, fret: 2},
            {finger: 1, string: STR_E, fret: 1},
            {finger: 3, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "gmaj7": {
        strum: [true, true, true, true],
        fingers: [],
        barres: [
            {finger: 1, fret: 2, from: STR_C, to: STR_A}
        ],
        shift: 0
    },
    "gm7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 2, string: STR_C, fret: 2},
        ],
        barres: [
            {finger: 1, fret: 1, from: STR_E, to: STR_A}
        ],
        shift: 0
    },
    // "gmmaj7": {},
    // "gdim7": {},
    // "ghdim7": {},

    "abmaj": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 2, string: STR_C, fret: 3},
            {finger: 4, string: STR_E, fret: 4},
            {finger: 3, string: STR_A, fret: 3},
        ],
        barres: [],
        shift: 0
    },
    "abmin": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 3, string: STR_C, fret: 3},
            {finger: 4, string: STR_E, fret: 4},
            {finger: 2, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    // "absus2": {},
    // "absus4": {},
    "abdim": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 2, string: STR_C, fret: 2},
            {finger: 4, string: STR_E, fret: 4},
            {finger: 3, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    "ab+": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 4, string: STR_A, fret: 3},
        ],
        barres: [],
        shift: 0
    },
    "abdom7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 3, string: STR_C, fret: 3},
            {finger: 2, string: STR_E, fret: 2},
            {finger: 4, string: STR_A, fret: 3},
        ],
        barres: [],
        shift: 0
    },
    "abmaj7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
        ],
        barres: [
            {finger: 4, fret: 3, from: STR_C, to: STR_A}
        ],
        shift: 0
    },
    "abm7": {
        strum: [true, true, true, true],
        fingers: [
            {finger: 1, string: STR_G, fret: 1},
            {finger: 4, string: STR_C, fret: 3},
            {finger: 2, string: STR_E, fret: 2},
            {finger: 3, string: STR_A, fret: 2},
        ],
        barres: [],
        shift: 0
    },
    // "abmmaj7": {},
    // "abdim7": {},
    // "abhdim7": {},
};
