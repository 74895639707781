import React from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import "firebase/functions";
import Input from "./Input";
import Button from "./Button";
import Loader from "./Loader";
import "./stylesheets/NewProjectModal.scss";
import { faChevronRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import CircleSelector from "./CircleSelector";
import { getPitchDisplayName, PITCH_NAMES_LIST } from "../constants/note-definitions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class NewProjectModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            title: "",
            tempo: 80,
            timeSignatureNumerator: 4,
            timeSignatureDenominator: 4,
            key: 3 // C
        };
        this.functions = firebase.functions();
    }
    
    createNewProject = () => {
        if(!this.fieldsAreValid()) return;
        this.setState({loading: true});
        const fn = this.functions.httpsCallable("createProject");
        fn({
            title: this.state.title,
            tempo: this.state.tempo,
            timeSignatureNumerator: this.state.timeSignatureNumerator,
            timeSignatureDenominator: this.state.timeSignatureDenominator,
            key: this.state.key
        }).then((res) => {
            const data = res.data;
            window.location.href = `/studio/${data.id}`;
        }, (e) => {
            console.error(e);
            const code = e.code;
            this.setState({loading: false});
            this.props.onError(code);
        });
    }

    fieldsAreValid = () => {
        return (
            this.state.title.length > 0 &&
            this.state.tempo >= 1 && this.state.tempo < 300 &&
            this.state.timeSignatureNumerator > 0 &&
            this.state.timeSignatureDenominator > 0
        )
    }

    render(){
        if(this.state.loading) {
            return (
                <Loader />
            );
        }
        return (
            <div className="modal-shade">
                <div className="modal new-project-modal">
                    <span className="close-button" onClick={() => {
                        this.props.onClose();
                    }}>
                        <FontAwesomeIcon icon={faTimes} />
                    </span>
                    <h1>New Project</h1>
                    <h2>Project Title</h2>
                    <Input placeholder="Give it a name!" value={this.state.title} onChange={(e) => {
                        this.setState({title: e.target.value});
                    }} />
                    <h2>Tempo</h2>
                    <Input type="number" value={this.state.tempo} onChange={(e) => {
                        let parsed = parseInt(e.target.value);
                        if(isNaN(parsed)) return;
                        if(parsed > 300) parsed = 300;
                        if(parsed < 1) parsed = 1;
                        this.setState({tempo: parsed});
                    }} />
                    <h2>Time Signature</h2>
                    <div id="time-signature-section">
                        <div>
                            <p>Beats per measure:</p>
                            <Input type="number" value={this.state.timeSignatureNumerator} onChange={(e) => {
                                let parsed = parseInt(e.target.value);
                                if(isNaN(parsed)) return;
                                if(parsed > 32) parsed = 32;
                                if(parsed < 1) parsed = 1;
                                this.setState({timeSignatureNumerator: parsed});
                            }} />
                            <p>Beat denomination:</p>
                            <Input type="number" value={this.state.timeSignatureDenominator} onChange={(e) => {
                                let parsed = parseInt(e.target.value);
                                if(isNaN(parsed)) return;
                                if(parsed > 32) parsed = 32;
                                if(parsed < 1) parsed = 1;
                                this.setState({timeSignatureDenominator: parsed});
                            }} />
                        </div>
                        <p id="time-sig-display">
                            {this.state.timeSignatureNumerator} <br />
                            {this.state.timeSignatureDenominator}
                        </p>
                    </div>
                    <h2>Key</h2>
                    <p>Projects use relative modes, e.g. if your project is in A minor, set the key to C.</p>
                    <CircleSelector labels={
                        PITCH_NAMES_LIST.map(i => getPitchDisplayName(i).toUpperCase())
                    } value={this.state.key} onChange={(val) => {
                        this.setState({key: val});
                    }} />

                    <div style={{
                        textAlign: "right",
                        marginTop: "40px"
                    }}>
                        <Button content="Create project" jumbo icon={faChevronRight} iconPosition="right" disabled={!this.fieldsAreValid()} onClick={this.createNewProject} />
                    </div>
                </div>
            </div>
        );
    }
}

NewProjectModal.propTypes = {
    onClose: PropTypes.func,
    onError: PropTypes.func
};

export default NewProjectModal;