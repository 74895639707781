import React from "react";

class SettingsPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        };
    }
    
    render(){
        return (
            <div>
                <h1>This is the user settings page!</h1>
            </div>
        );
    }
}

SettingsPage.propTypes = {
    
};

export default SettingsPage;