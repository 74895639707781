import React from "react";
import PropTypes from "prop-types";
import "./stylesheets/ChordOverview.scss";
import { getPitchFromDegree } from "../constants/note-definitions";
import { serializeChord, simplifyChord } from "../constants/chord-serializer";
import INSTRUMENTS from "../constants/instruments";
import ChordDiagram from "./ChordDiagram";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";

class ChordOverview extends React.Component {
    render(){
        const instrument = INSTRUMENTS[this.props.instrument];
        return (
            <div id="chord-overview">
                {this.props.sequence.map((chord, i) => {
                    if(this.props.simplify) chord = simplifyChord(chord);
                    
                    if(chord.type === "break") {
                        return (
                            <div className="break" key={i}>
                                <div className="break-line" />
                            </div>
                        );
                    } else if(chord.type === "repeat") {
                        return (
                            <div className="repeat" key={i} style={{
                                width: `${instrument.defaultScale * 90}px`
                            }}>
                                <FontAwesomeIcon icon={faRefresh} className="repeat-icon" />
                                <p>x{chord.times}</p>
                            </div>
                        );
                    } else if(chord.type === "chord") {
                        const pitch = getPitchFromDegree(this.props.keyNum, chord.root);
                        const chordName = `${pitch}${chord.quality}`;
                        const displayName = serializeChord(
                            chord,
                            this.props.relative ? null : this.props.keyNum,
                            true
                        );
                        return (
                            <div className="item" key={i}>
                                <p>{displayName}</p>
                                <ChordDiagram
                                    instrument={this.props.instrument}
                                    chord={chordName}
                                    width={`${instrument.defaultScale * 90}px`}
                                    scale={0.5}
                                />
                            </div>
                        );
                    }
                    return null;
                })}
            </div>
        );
    }
}

ChordOverview.propTypes = {
    sequence: PropTypes.arrayOf(PropTypes.object),
    keyNum: PropTypes.number,
    instrument: PropTypes.string,
    relative: PropTypes.bool
};

export default ChordOverview;