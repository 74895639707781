/*
Chord object:
{
    type: "chord",
    duration: {
        numerator: int,
        denominator: int
    }, // optional
    root: 0-11, // root is RELATIVE. No key information is stored with the chord.
    quality: string,
    alterations: [
        {
            note: 0-11,
            delta: -1 for flat, +1 for sharp
        }
    ],
    additions: [
        {
            note: 0-∞ // chords stack up, does not end at the octave
        }
    ],
    omissions: [
        {
            note: 0-∞
        }
    ],
    poly: [], // TODO for future use
    inversion: 0-n where n is the number of notes in the quality
}

Break object (newline):
{
    type: "break"
}

Repeat object:
{
    type: "repeat",
    times: int
}

Error object:
{
    type: "error",
    message: string
}
*/

import React from "react";
import { CHORD_QUALITIES, EXTENDED_CHORD_BASE_QUALITIES, getNotesInChord } from "./chord-definitions";
import { DEGREE_NAMES, DEGREE_NAMES_LIST, getEnharmonicNameFromDegree, getScaleDegreeFromPitchNumber, PITCH_NAMES, PITCH_NAMES_LIST, PITCH_NUMBER_TO_SCALE_DEGREE_NEAREST_NOTE, scaleDegreeToPitchNumber } from "./note-definitions";

const REPEAT_REGEX = /^\(x(\d+)\)$/;

/**
 * Serialized a chord object into a human-readable shorthand string.
 * @param {object} chord The chord object to serialize.
 * @param {int|null} key The key the chord object is relative to. Set to null to serialize using relative notation.
 * @param {bool} formatAsHtml Set to true to return HTML formatting.
 * @returns {string} The serialized chord.
 */
export function serializeChord(chord, key = null, formatAsHtml = false){
    if(chord.type === "break") {
        return formatAsHtml ? (
            <span className="serialized-chord break">/</span>
        ) : "/";
    }
    if(chord.type === "repeat") {
        return formatAsHtml ? (
            <span className="serialized-chord repeat">(x{chord.times})</span>
        ) : `(x${chord.times})`;
    }

    if(chord.type === "chord") {
        let ret = "";
        let htmlRet = [];
        if(("duration" in chord) && chord.duration != null && chord.duration.numerator !== chord.duration.denominator){
            if(chord.duration.denominator === 1) {
                ret += `${chord.duration.numerator}`;
                htmlRet.push(
                    <span className="duration" key="duration">{chord.duration.numerator}</span>
                );
            }
            else {
                ret += `${chord.duration.numerator}/${chord.duration.denominator}`;
                htmlRet.push(
                    <span className="duration" key="duration">{chord.duration.numerator}/{chord.duration.denominator}</span>
                )
            }
        }

        // Collapse (add9)(add11)(add13) into "13" etc
        const additionsTrimmed = [...chord.additions];
        let shouldDisplayExtendedChordNotation = false;
        let highest = 7;
        if(["dom7", "maj7", "m7", "mmaj7", "augmaj7", "+7", "hdim7", "dim7"].includes(chord.quality)) {
            while(true) {
                const idx = additionsTrimmed.findIndex(i => i.note == scaleDegreeToPitchNumber(highest + 2));
                if(idx == -1) break;
                highest += 2;
                additionsTrimmed.splice(idx, 1);
            }
            if(highest > 7) {
                shouldDisplayExtendedChordNotation = true;
            }
        }

        // If the note in the bass is an addition, remove it from the additions list
        const unmoddedBassNote = getNotesInChord(chord)[chord.inversion];
        const idxOfBassAddition = additionsTrimmed.findIndex(i => i.note == unmoddedBassNote);
        if(idxOfBassAddition > -1) additionsTrimmed.splice(idxOfBassAddition, 1);

        const qualityInfo = CHORD_QUALITIES[chord.quality];
        let root = key === null ? DEGREE_NAMES_LIST[chord.root] : getEnharmonicNameFromDegree(key, chord.root);
        root = qualityInfo.capitalize ? root.toUpperCase() : root.toLowerCase();
        ret += root;
        htmlRet.push(
            <span className="root" key="root">{root}</span>
        )
    
        if(shouldDisplayExtendedChordNotation) {
            ret += EXTENDED_CHORD_BASE_QUALITIES[chord.quality];
            ret += highest;
            htmlRet.push(
                <span className="quality" key="quality">
                    {EXTENDED_CHORD_BASE_QUALITIES[chord.quality]}
                    {highest}
                </span>
            );
        } else {
            const qualityDisplay = qualityInfo.aliases[0];
            ret += qualityDisplay;
            htmlRet.push(
                <span className="quality" key="quality">{qualityDisplay}</span>
            );
        }

        for(let i = 0; i < chord.alterations.length; i++) {
            const alteration = chord.alterations[i];
            const scaleDegree = getScaleDegreeFromPitchNumber(alteration.note);
            let flatOrSharp = "";
            if(alteration.delta == -1) {
                flatOrSharp = "♭";
            } else if(alteration.delta == 1) {
                flatOrSharp = "♯";
            }
            ret += `(${flatOrSharp}${scaleDegree})`;
            htmlRet.push(
                <span className="extension" key={i}>({flatOrSharp}{scaleDegree})</span>
            );
        }
        for(let i = 0; i < additionsTrimmed.length; i++) {
            const addition = additionsTrimmed[i];
            const scaleDegree = getScaleDegreeFromPitchNumber(addition.note);
            
            const nearestNote = scaleDegreeToPitchNumber(PITCH_NUMBER_TO_SCALE_DEGREE_NEAREST_NOTE[addition.note]);
            const originalChordIncludesBaseNote = CHORD_QUALITIES[chord.quality].notes.includes(nearestNote);
            
            let paren = "";
            if(/b|#/.test(scaleDegree) && !originalChordIncludesBaseNote) {
                paren = `(${scaleDegree})`.replace("#", "♯").replace("b", "♭");
            } else {
                paren = `(add${scaleDegree})`.replace("#", "♯").replace("b", "♭");
            }
            ret += paren;
            htmlRet.push(
                <span className="extension" key={i}>{paren}</span>
            );
        }
        for(let i = 0; i < chord.omissions.length; i++) {
            const omission = chord.omissions[i];
            const scaleDegree = getScaleDegreeFromPitchNumber(omission.note);
            const paren = `(no${scaleDegree})`.replace("#", "♯").replace("b", "♭");
            ret += paren;
            htmlRet.push(
                <span className="extension" key={i}>{paren}</span>
            );
        }


        if(chord.inversion != 0){
            const bassNote = getNotesInChord(chord)[chord.inversion] % PITCH_NAMES_LIST.length;
            if(key === null) {
                const bassDegree = DEGREE_NAMES_LIST[(bassNote + chord.root) % PITCH_NAMES_LIST.length].toUpperCase();
                ret += `/${bassDegree}`;
                htmlRet.push(<span className="slash" key="slash">/</span>);
                htmlRet.push(
                    <span className="bass" key="bass">{bassDegree}</span>
                );
            } else {
                const bassName = getEnharmonicNameFromDegree((key + chord.root) % PITCH_NAMES_LIST.length, bassNote).toUpperCase();
                ret += `/${bassName}`;
                htmlRet.push(<span className="slash" key="slash">/</span>);
                htmlRet.push(
                    <span className="bass" key="bass">{bassName}</span>
                );
            }
        }
        if(formatAsHtml) {
            return (
                <span className="serialized-chord chord">{htmlRet}</span>
            )
        }
        return ret;
    }

    throw new Error("Invalid chord type " + chord.type);
}

/**
 * Parses a chord object from a human-readable shorthand string.
 * @param {string} chordString The chord string to parse.
 * @param {int} key the key the chord is relative to.
 * @param {bool} includeDuration set to true to parse chord duration information.
 * @returns {object|null} The parsed chord object, or null if no chord could be parsed.
 */
export function parseChord(chordString, key, includeDuration = false){
    if(chordString.length < 1) return;

    if(chordString === "/") return { type: "break" };
    const repeatMatch = chordString.match(REPEAT_REGEX);
    if(repeatMatch) {
        return {
            type: "repeat",
            times: parseInt(repeatMatch[1 /* first capture group */])
        };
    }

    let duration = null;
    if(includeDuration){
        const CHORD_DURATION_CHARS = ['/', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
        let durationSegment = "";
        for(let i = 0; i < chordString.length; i++){
            if(!CHORD_DURATION_CHARS.includes(chordString[i])){
                chordString = chordString.substring(i);
                break;
            }
            durationSegment += chordString[i];
        }
        if(chordString.length == 0) {
            return {
                type: "error",
                message: "Empty chord. Check for extra spaces."
            };
        }
        if(durationSegment.length == 0){
            duration = {numerator: 1, denominator: 1};
        } else if(durationSegment.includes('/')){
            const split = durationSegment.split('/');
            if(split.length !== 2) {
                return {
                    type: "error",
                    message: "Chord duration has too many slash (/) symbols."
                };
            }
            const numerator = parseInt(split[0]);
            const denominator = parseInt(split[1]);
            if(isNaN(numerator) || isNaN(denominator)) {
                return {
                    type: "error",
                    message: "Chord duration is not a number or fraction."
                };
            }
            if(numerator == 0 || denominator == 0) {
                return {
                    type: "error",
                    message: "Chord duration fraction cannot have a zero in its numerator or denominator."
                };
            }
            // TODO: Reduce fraction
            duration = {numerator, denominator};
        } else {
            // TODO: Handle decimal numbers
            let parsedDuration = parseInt(durationSegment);
            if(isNaN(parsedDuration) || parsedDuration == 0) {
                return {
                    type: "error",
                    message: "Chord duration cannot be zero."
                };
            }
            duration = {numerator: parsedDuration, denominator: 1};
        }
    }

    let degree = null;
    let degreeSegment;
    let degreeSegmentUnlowercased;
    for(let len = 4; len > 0; len--){ // Max # of characters in key is 4. Greedy match: take as many characters as possible
        degreeSegmentUnlowercased = chordString.substring(0, len);
        degreeSegment = degreeSegmentUnlowercased.toLowerCase();
        if(degreeSegment in PITCH_NAMES){
            degree = (PITCH_NAMES_LIST.length + PITCH_NAMES[degreeSegment] - key) % PITCH_NAMES_LIST.length;
            chordString = chordString.substring(len);
            break;
        } else if(degreeSegment in DEGREE_NAMES){
            degree = DEGREE_NAMES[degreeSegment];
            chordString = chordString.substring(len);
            break;
        }
    }
    if(degree === null) {
        return {
            type: "error",
            message: `Degree or note name "${degreeSegment}" not recognized. You can use relative (I, ii, etc) or absolute (C, C#, Db) names.`
        };
    }
    
    const endOfQualityString = Math.min(
        chordString.indexOf('(') == -1 ? chordString.length : chordString.indexOf('('),
        chordString.indexOf('/') == -1 ? chordString.length : chordString.indexOf('/')
    );
    const qualitySegment = chordString.substring(0, endOfQualityString);
    let quality = null;
    const alterations = [];
    const additions = [];
    const omissions = [];
    if(qualitySegment.length == 0){
        // Special case: no chord quality; default to major or minor depending on capitalization
        quality = (/[A-Z]/).test(degreeSegmentUnlowercased) ? "maj" : "min"; // does string contain a capital letter?
    } else {
        for(const q of Object.keys(CHORD_QUALITIES)){
            if(CHORD_QUALITIES[q].aliases.includes(qualitySegment)){
                quality = q;
                break;
            }
        }
    }
    if(quality == null) {
        // Quality does not match one of the specified qualities. Now check for "tall chords" e.g. C9, Cmaj11, Cm13
        const parseResult = findTallNumberFromQualitySegment(qualitySegment);
        const tallNumber = parseResult.tallNumber;
        quality = parseResult.quality;
        
        if(tallNumber == null) {
            // Tall chord did not match, it's an invalid quality
            return {
                type: "error",
                message: "Chord quality not recognized."
            };
        }
        if(isNaN(tallNumber)) {
            return {
                type: "error",
                message: "Extended chord must end in a number, like 9, 11, or 13."
            };
        }
        if(tallNumber < 9) {
            return {
                type: "error",
                message: "Extended chord must start at 9 or above."
            };
        }
        if(tallNumber % 2 != 1) {
            return {
                type: "error",
                message: "Extended chords can only have odd numbers, like 9, 11, or 13."
            };
        }
        for(let scaleDegree = 9; scaleDegree <= tallNumber; scaleDegree += 2) {
            additions.push({
                note: scaleDegreeToPitchNumber(scaleDegree)
            });
        }
    }
    chordString = chordString.substring(qualitySegment.length);

    const extensionsString = chordString.split("/")[0].toLowerCase();
    const extensions = extensionsString.split(/\(|\)/).filter(i => i.length > 0);
    
    for(const extension of extensions) {
        if(/^add(b|-|dim|#|\+|aug)?\d+$/.test(extension)) {
            // Add the note
            const degreeStr = extension.match(/\d+/)[0];
            if(!degreeStr) {
                return {
                    type: "error",
                    message: "Addition must specify the scale degree to add."
                };
            }
            let degree = parseInt(degreeStr);
            if(isNaN(degree)) {
                return {
                    type: "error",
                    message: "Addition must specify the scale degree to add."
                };
            }
            let pitchNumber = scaleDegreeToPitchNumber(degree);
            if(/#|\+|aug/.test(extension)) pitchNumber++;
            else if(/b|-|dim/.test(extension)) pitchNumber--;
            additions.push({ note: pitchNumber });
        } else if(/^(b|#)\d+$/.test(extension)) {
            // Alter the note if it's in the chord, else add it
            const degreeStr = extension.match(/\d+/)[0];
            if(!degreeStr) {
                return {
                    type: "error",
                    message: "Addition must specify the scale degree to add."
                };
            }
            let degree = parseInt(degreeStr);
            if(isNaN(degree)) {
                return {
                    type: "error",
                    message: "Addition must specify the scale degree to add."
                };
            }
            let pitchNumber = scaleDegreeToPitchNumber(degree);
            let delta = null;
            if(/#|\+|aug/.test(extension)) delta = 1;
            else if(/b|-|dim/.test(extension)) delta = -1;
            if(delta == null) {
                return {
                    type: "error",
                    message: "Alteration did not specify # or b"
                };
            }
            const tempChord = {
                type: "chord",
                quality,
                additions: additions,
                alterations: [],
                omissions: []
            };
            if(getNotesInChord(tempChord).includes(pitchNumber)) {
                alterations.push({ note: pitchNumber, delta });
            } else {
                additions.push({ note: pitchNumber + delta });
            }
        } else if(/^no(b|#)?\d+$/.test(extension)) {
            // Omit the note if it's in the chord
            const degreeStr = extension.match(/\d+/)[0];
            if(!degreeStr) {
                return {
                    type: "error",
                    message: "Addition must specify the scale degree to add."
                };
            }
            let degree = parseInt(degreeStr);
            if(isNaN(degree)) {
                return {
                    type: "error",
                    message: "Addition must specify the scale degree to add."
                };
            }
            let pitchNumber = scaleDegreeToPitchNumber(degree);
            if(/#|\+|aug/.test(extension)) pitchNumber++;
            else if(/b|-|dim/.test(extension)) pitchNumber--;
            const tempChord = {
                type: "chord",
                quality,
                additions: additions,
                alterations: [],
                omissions: []
            };
            if(getNotesInChord(tempChord).includes(pitchNumber)) {
                omissions.push({ note: pitchNumber });
            } else {
                return {
                    type: "error",
                    message: "Omitted note is not in the chord specified."
                };
            }
        } else {
            return {
                type: "error",
                message: "Extension not recognized. Try (add9), (b5), or (no3)."
            };
        }
    }
    chordString = chordString.substring(extensionsString.length);

    // TODO: Disambiguate between polychords, secondary chords, and inversions
    const inversionSegment = chordString.includes("/") ? chordString.split("/")[1].toLowerCase() : null;
    let inversion = 0;
    if(inversionSegment != null)
    {
        const tempChord = {
            type: "chord",
            quality,
            alterations,
            additions,
            omissions
        };
        const notesInChordFlattened = getNotesInChord(tempChord).map(i => i % PITCH_NAMES_LIST.length);
        if(inversionSegment in PITCH_NAMES) {
            const inversionDegreeRelativeToChord = (PITCH_NAMES_LIST.length + PITCH_NAMES[inversionSegment] - ((degree + key) % PITCH_NAMES_LIST.length)) % PITCH_NAMES_LIST.length;
            if(notesInChordFlattened.includes(inversionDegreeRelativeToChord)) {
                inversion = notesInChordFlattened.indexOf(inversionDegreeRelativeToChord);
            } else {
                // Bass note is not in chord. Add it as an addition, and specify the inversion
                additions.push({ note: inversionDegreeRelativeToChord });
                const tempChordRedux = {
                    type: "chord",
                    quality,
                    alterations,
                    additions,
                    omissions
                };
                const notesInChordFlattenedRedux = getNotesInChord(tempChordRedux).map(i => i % PITCH_NAMES_LIST.length);
                inversion = notesInChordFlattenedRedux.indexOf(inversionDegreeRelativeToChord);
            }
        } else if(inversionSegment in DEGREE_NAMES) {
            const inversionDegree = DEGREE_NAMES[inversionSegment];
            const inversionDegreeRelativeToChord = (PITCH_NAMES_LIST.length + inversionDegree - degree) % PITCH_NAMES_LIST.length;
            if(notesInChordFlattened.includes(inversionDegreeRelativeToChord)) {
                inversion = notesInChordFlattened.indexOf(inversionDegreeRelativeToChord);
            } else {
                // Bass note is not in chord. Add it as an addition, and specify the inversion
                additions.push({ note: inversionDegreeRelativeToChord });
                const tempChordRedux = {
                    type: "chord",
                    quality,
                    alterations,
                    additions,
                    omissions
                };
                const notesInChordFlattenedRedux = getNotesInChord(tempChordRedux).map(i => i % PITCH_NAMES_LIST.length);
                inversion = notesInChordFlattenedRedux.indexOf(inversionDegreeRelativeToChord);
            }
        } else {
            return {
                type: "error",
                message: `Bass note "${inversionSegment}" not recognized. You can use relative (I, ii, etc) or absolute (C, C#, Db) names.`
            };
        }
    }

    // Remove additions/omissions/alterations of the same note
    for(let alterationIdx = alterations.length - 1; alterationIdx >= 0; alterationIdx--) {
        const alteration = alterations[alterationIdx];
        for(let additionIdx = 0; additionIdx < additions.length; additionIdx++) {
            const addition = additions[additionIdx];
            if(addition.note == alteration.note) {
                addition.note = alteration.note + alteration.delta;
                alterations.splice(alterationIdx, 1);
            }
        }
    }
    for(let omissionIdx = omissions.length - 1; omissionIdx >= 0; omissionIdx--) {
        const omission = omissions[omissionIdx];
        for(let additionIdx = additions.length - 1; additionIdx >= 0; additionIdx--) {
            const addition = additions[additionIdx];
            if(addition.note == omission.note) {
                additions.splice(additionIdx, 1);
                omissions.splice(omissionIdx, 1);
                break;
            }
        }
    }

    return {
        type: "chord",
        duration,
        root: degree,
        quality,
        inversion,
        alterations,
        additions,
        omissions,
        poly: [],
    };
}

function findTallNumberFromQualitySegment(qualitySegment) {
    if(/^(dom)?\d+$/.test(qualitySegment)){
        const tallNumber = parseInt(qualitySegment);
        const quality = "dom7";
        return { tallNumber, quality };
    }
    if(/^maj\d+$/.test(qualitySegment)) {
        const tallNumber = parseInt(qualitySegment.replace("maj", ""));
        const quality = "maj7";
        return { tallNumber, quality };
    }
    if(/^m(in)?\d+$/.test(qualitySegment)) {
        const tallNumber = parseInt(qualitySegment.replace("min", "").replace("m", ""));
        const quality = "m7";
        return { tallNumber, quality };
    }
    if(/^mmaj\d+$/.test(qualitySegment)) {
        const tallNumber = parseInt(qualitySegment.replace("mmaj", ""));
        const quality = "mmaj7";
        return { tallNumber, quality };
    }
    if(/^augmaj\d+$/.test(qualitySegment)) {
        const tallNumber = parseInt(qualitySegment.replace("augmaj", ""));
        const quality = "augmaj7";
        return { tallNumber, quality };
    }
    if(/^(\+|aug)\d+$/.test(qualitySegment)) {
        const tallNumber = parseInt(qualitySegment.replace("aug", "").replace("+", ""));
        const quality = "+7";
        return { tallNumber, quality };
    }
    if(/^(ø|hdim)\d+$/.test(qualitySegment)) {
        const tallNumber = parseInt(qualitySegment.replace("ø", "").replace("hdim", ""));
        const quality = "hdim7";
        return { tallNumber, quality };
    }
    if(/^(°|dim)\d+$/.test(qualitySegment)) {
        const tallNumber = parseInt(qualitySegment.replace("°", "").replace("dim", ""));
        const quality = "dim7";
        return { tallNumber, quality };
    }
    return [null, null];
}

/**
 * Parses a quick-input string of chords into a list of chord objects.
 * @param {string} val The string of chord names.
 * @param {int} key The key the chord is relative to.
 * @returns {object} {invalid: bool, sequence: object[]}
 */
export function parseQuickInput(val, key) {
    const split = val.trim().split(" ").filter(i => i.length > 0);
    const sequence = split.map(i => {
        const parsed = parseChord(i, key, true);
        return parsed;
    });
    const firstErrorIndex = sequence.findIndex(i => i.type === "error");
    const firstError = sequence[firstErrorIndex];
    const errorChord = split[firstErrorIndex];
    return {
        firstError,
        errorChord,
        sequence: firstError == null ? sequence : []
    };
}

export function simplifyChord(chord) {
    if(chord.type !== "chord") return chord;
    const ret = {...chord};
    const qualityInfo = CHORD_QUALITIES[ret.quality];
    ret.quality = qualityInfo.simplifiesTo;
    ret.inversion = 0;
    return ret;
}