import React from "react";
import PropTypes from "prop-types";
import firebase from "firebase/app";
import "firebase/auth";

import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import Button from "../components/Button";
import Loader from "../components/Loader";
import Error from "../components/Error";
import "./stylesheets/AuthPage.scss";

class AuthPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            error: null
        };
        this.auth = firebase.auth();
        const params = new URLSearchParams(window.location.search);
        this.dest = params.get("dest");
        if(this.dest == null || this.dest == undefined || this.dest.length == 0){
            this.state.error = "auth/no-dest";
        }

        firebase.auth().getRedirectResult().then(result => {
            if(result.user == null){
                this.setState({loading: false});
                return;
            }
            this.onSignInSuccess(result);
        }).catch(e => {
            console.error(e);
            this.setState({error: "auth/unknown"});
        });
        
    }

    onSignInSuccess = (res) => {
        if(res.additionalUserInfo.isNewUser){
            // Redirect to onboarding page to make time for cloud function to run
            window.location.href = "/onboarding?dest=" + this.dest;
            return;
        }
        window.location.href = decodeURIComponent(this.dest);
    }
    
    render(){
        if(this.state.error != null){
            return (
                <Error code={this.state.error} onDismiss={() => {
                    this.setState({error: null});
                }} />
            )
        }

        if(this.state.loading){
            return (
                <Loader />
            );
        }
        
        return (
            <div>
                <div id="auth-main-content">
                    <div id="centered-pad">
                        <h1>Hello there!</h1>
                        <Button primary content="Sign in with Google" icon={faGoogle} disabled={this.state.loading} onClick={this.buttonClickHandler} />
                    </div>
                </div>
            </div>
        );
    }

    buttonClickHandler = () => {
        if(this.state.loading) return;
        this.setState({loading: true});
        const provider = new firebase.auth.GoogleAuthProvider();
        this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
            this.auth.signInWithPopup(provider).then((res) => {
                this.onSignInSuccess(res);
            }, (err) => {
                console.error(err);
                switch(err.code){
                    case "auth/auth-domain-config-required":
                    case "auth/operation-not-allowed":
                    case "auth/operation-not-supported-in-this-environment":
                    case "auth/unauthorized-domain": {
                        this.setState({error: "auth/unknown"});
                        break;
                    }
                    case "auth/account-exists-with-different-credential": {
                        this.setState({error: "auth/account-exists-with-different-credential"});
                        break;
                    }
                    case "auth/cancelled-popup-request": {
                        break;
                    }
                    case "auth/popup-blocked": {
                        firebase.auth().signInWithRedirect(provider);
                        break;
                    }
                    case "auth/popup-closed-by-user": {
                        this.setState({loading: false});
                        break;
                    }
                }
            });
        });
    }
}

AuthPage.propTypes = {
    
};

export default AuthPage;