export const DO = 0;
export const DI = 1;
export const RAH = 1;
export const RE = 2;
export const RI = 3;
export const ME = 3;
export const MI = 4;
export const FA = 5;
export const FI = 6;
export const SE = 6;
export const SOL = 7;
export const SI = 8;
export const LE = 8;
export const LA = 9;
export const LI = 10;
export const TE = 10;
export const TI = 11;

export const OCTAVE = 12;

const SOLFEGE = {DO, DI, RAH, RE, RI, ME, MI, FA, FI, SE, SOL, SI, LE, LA, LI, TE, TI, OCTAVE};
export default SOLFEGE;