import React from "react";
import PropTypes from "prop-types";
import Button from "../components/Button";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

class OnboardingPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
        const params = new URLSearchParams(window.location.search);
        this.dest = params.get("dest");
    }

    onContinueButtonClick = () => {
        if(this.dest == null || this.dest == undefined || this.dest.length == 0){
            window.location.href = "/projects";
            return;
        }
        window.location.href = decodeURIComponent(this.dest);
    }
    
    render(){
        return (
            <div>
                <h1>Onboarding page</h1>
                <Button content="Let's go" icon={faChevronRight} iconPosition="right" onClick={this.onContinueButtonClick} />
            </div>
        );
    }
}

OnboardingPage.propTypes = {
    
};

export default OnboardingPage;