import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./stylesheets/TabsFAQ.scss";

export default class TabsFAQ extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            expanded: false
        };
    }

    toggleExpanded = () => {
        this.setState({expanded: !this.state.expanded});
    }

    render(){

        const content = (
            <div id="tabs-faq">
                <h2>Basics</h2>
                <p>
                    Use note names or roman numerals to describe chords:<br />
                    <code>F</code>, <code>IV</code>, <code>A#</code>, <code>#VI</code>
                </p>

                <h2>Qualities</h2>
                <p>
                    Use conventional lead sheet quality shorthand to describe chord qualities:<br />
                    <code>Cmaj</code>, <code>ivm</code>, <code>fdim7</code>, <code>iiø7</code><br />
                    <em>Hint: use lowercase as shorthand for minor!</em>
                </p>

                <h2>Duration</h2>
                <p>
                    To make a chord play multiple times when in Play mode, specify a number or fraction as a duration:<br />
                    <code>4C</code>, <code>1/2F</code>
                </p>

                <h2>Inversions</h2>
                <p>
                    To play a chord in a specific inversion, use a slash followed by the bass note:<br />
                    <code>C/E</code>, <code>C/V</code>, <code>C7/Bb</code><br />
                    If a bass note that's not in the chord is specified, it will be added to the chord:<br />
                    <code>C/A</code> is the same as <code>C(add6)/A</code>
                </p>

                <h2>Extended chords</h2>
                <p>
                    You can use odd numbers to specify extended chords:<br />
                    <code>C9</code>, <code>Cmaj11</code>, <code>cm13</code>, <code>cmmaj15</code>, <code>Caugmaj17</code>, <code>C+19</code>, <code>chdim21</code>, <code>cdim23</code><br />
                    For example, <code>Cmaj11</code> is the same as <code>Cmaj7(add9)(add11)</code>.
                </p>

                <h2>Additions</h2>
                <p>
                    Use "add" in parentheses to add an upper extension:<br />
                    <code>C(add11)</code><br />
                    You can use accidentals in additions:<br />
                    <code>C(add#11)</code><br />
                    If you use an accidental, you can omit the "add" (as long as the note is not already in the chord; then it's treated like an alteration):<br />
                    <code>C(#11)</code><br />
                </p>

                <h2>Alterations</h2>
                <p>
                    Use "#" or "b" in parentheses to add an alteration:<br />
                    <code>C(b5)</code>, <code>C(#5)</code><br />
                </p>

                <h2>Omissions</h2>
                <p>
                    Use "no" in parentheses to omit a note:<br />
                    <code>C(no5)</code>, <code>C11(no9)</code>
                </p>

                <h2>Putting it together</h2>
                <p>
                    Some complex chords you can make:<br />
                    <code>Cmaj7(#11)(no5)/E</code><br />
                    <code>1/4ivm7(#5)</code><br />
                    <code>1/2#VI7/I</code>
                    </p>
            </div>
        );

        return (
            <>
                <button className="nostyle dropdown-button" onClick={this.toggleExpanded} id="tabs-faq-button">
                    About chord notation
                    <FontAwesomeIcon icon={this.state.expanded ? faChevronUp : faChevronDown} />
                </button>
                {
                    this.state.expanded && content
                }
            </>
        );
    }
}
