import "./stylesheets/ChordLibraryPage.scss";
import React from "react";
import SongProfile from "../components/SongProfile";
import Loader from "../components/Loader";
import { addParamToURL, CHORD_LIBRARY_DATA } from "../constants/consts";
import { Link, Route, Switch } from "react-router-dom";
import LibraryTagPage from "../components/LibraryTagPage";
import LibraryAllSongs from "../components/LibraryAllSongs";
import LibrarySingleSong from "../components/LibrarySingleSong";
import Button from "../components/Button";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { PITCH_NAMES_LIST } from "../constants/note-definitions";

export default class ChordLibraryPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            songs: {},
            tagNames: [],
            songNamesByTag: {},
            
            // default settings
            instrument: "",
            mode: "",
            key: "",
            tempo: "",
            simplify: "",
        };
        this.loadDefaultSettingsFromURLParams();
        this.fetchData();
    }

    fetchData = async () => {
        let data;
        try {
            const response = await fetch(CHORD_LIBRARY_DATA + "/library-data.json", {cache: "reload"});
            data = await response.json();
        } catch(e) {
            console.error(e);
            this.setState({error: true});
            return;
        }
        if(!data) {
            console.error("Data is falsy! Data: ", data);
            this.setState({error: true});
            return;
        }
        try {
            // Set removes duplicates
            const tagNames = [... new Set(Object.keys(data).map((songName) => data[songName].tags).flat())];
            const songNamesByTag = {};
            for(const tag of tagNames) {
                songNamesByTag[tag] = Object.keys(data).filter(songId => data[songId].tags.includes(tag));
            }
            this.setState({
                loading: false,
                songs: data,
                tagNames,
                songNamesByTag
            });
        } catch(e) {
            console.error("Error parsing data! ", e);
            this.setState({error: true});
            return;
        }
    }

    loadDefaultSettingsFromURLParams = () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        if(urlParams.has("instrument")) this.state.instrument = urlParams.get("instrument");
        if(urlParams.has("mode")) this.state.mode = urlParams.get("mode");
        if(urlParams.has("key")) {
            const keyName = urlParams.get("key");
            if(PITCH_NAMES_LIST.includes(keyName))
                this.state.key = PITCH_NAMES_LIST.indexOf(keyName);
        }
        if(urlParams.has("tempo")) {
            const tempoNum = parseInt(urlParams.get("tempo"));
            if(!isNaN(tempoNum))
                this.state.tempo = urlParams.get("tempo");
        }
        if(urlParams.has("simplify")) this.state.simplify = urlParams.get("simplify") === "true";
    }

    onDefaultSettingChanged = (setting, value) => {
        const {history, location} = this.props;

        switch(setting) {
            case "instrument":
                addParamToURL("instrument", value, history, location);
                this.setState({instrument: value});
                break;
            case "mode":
                addParamToURL("mode", value, history, location);
                this.setState({mode: value});
                break;
            case "key":
                addParamToURL("key", PITCH_NAMES_LIST[value], history, location);
                this.setState({key: value});
                break;
            case "tempo":
                addParamToURL("tempo", value, history, location);
                this.setState({tempo: value});
                break;
            case "simplify":
                addParamToURL("simplify", value, history, location);
                this.setState({simplify: value});
                break;
            default:
                return;
        }
    }
    
    render(){
        const {error, loading, songs, tagNames, songNamesByTag, instrument, mode, key, tempo, simplify} = this.state;
        const defaultSettings = {instrument, mode, key, tempo, simplify};

        if(error) {
            return (
                <div id="chord-library-main-content" className="error">
                    <div className="wide-rails centered">
                        <h2 className="subtitle centered gradient-text">Chord Library</h2>
                        <p className="centered">Something went wrong loading the library!</p>
                        <Link to="/">
                            <Button jumbo content="Go Home" icon={faArrowRight} iconPosition="right" />
                        </Link>
                    </div>
                </div>
            );
        }

        if(loading) {
            return (
                <div id="chord-library-main-content">
                    <div className="wide-rails">
                        <h2 className="subtitle centered gradient-text">Chord Library</h2>
                        <Loader />
                    </div>
                </div>
            );
        }

        return (
            <div id="chord-library-main-content">
                <div className="wide-rails">
                    <h2 className="subtitle centered gradient-text">Chord Library</h2>
                    <Switch>
                        <Route exact path="/library">
                            <LibraryAllSongs
                                songs={songs}
                                tagNames={tagNames}
                                songNamesByTag={songNamesByTag}
                                onDefaultSettingChanged={this.onDefaultSettingChanged}
                                defaultSettings={defaultSettings}
                            />
                        </Route>
                        <Route path="/library/tag/:tagId" render={(props) =>
                            <LibraryTagPage
                                songs={songs}
                                tagNames={tagNames}
                                songNamesByTag={songNamesByTag}
                                onDefaultSettingChanged={this.onDefaultSettingChanged}
                                defaultSettings={defaultSettings}
                                {...props}
                            />
                        } />
                        <Route path="/library/song/:songId" render={(props) =>
                            <LibrarySingleSong
                                songs={songs}
                                tagNames={tagNames}
                                songNamesByTag={songNamesByTag}
                                onDefaultSettingChanged={this.onDefaultSettingChanged}
                                defaultSettings={defaultSettings}
                                {...props}
                            />
                        } />
                    </Switch>
                    
                </div>
            </div>
        );
    }
}