import { faBook, faGuitar, faMicrophone, faTable } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import LandingPageLink from "../components/LandingPageLink";
import "./stylesheets/LandingPage.scss";

class LandingPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        return (
            <div id="landing-main-content">
                <div className="rails">
                    <h1 className="accent title gradient-text" id="title">Jam.</h1>
                    <h2 id="subtitle">A collection of musical tools</h2>
                    <div>
                        <LandingPageLink
                            title="Song library"
                            description="A selection of my favorite songs and what makes them harmonically work."
                            url="/library"
                            icon={faBook}
                        />
                        <LandingPageLink
                            title="Chord chart"
                            description="Beautiful and printable chord charts for guitar and ukulele."
                            url="/chart"
                            icon={faTable}
                        />
                        <LandingPageLink
                            title="Custom tabs"
                            description="Create guitar and ukulele tabs for any chord progression."
                            url="/tab"
                            icon={faGuitar}
                        />
                        <LandingPageLink
                            title="Voicings generator"
                            description="Find the best inversions for your chord progression."
                            url="/voicings"
                            icon={faMicrophone}
                        />
                    </div>
                </div>
            </div>
        );
        // return (
        //     <div id="landing-main-content">
        //         <div className="rails">
        //             <h1 className="accent title gradient-text" id="title">Jam.</h1>
        //             <h2 id="subtitle">The easiest way to make music with friends.</h2>
        //             <div style={{
        //                 display: "flex",
        //                 flexDirection: "row",
        //                 alignItems: "center"
        //             }}>
        //                 <Link to="/projects">
        //                     <Button primary jumbo content="Get Jamming" icon={faChevronRight} iconPosition="right" style={{marginRight: "20px"}}/>
        //                 </Link>
        //                 <Button jumbo content="Learn more" icon={faChevronDown} iconPosition="right" />
        //             </div>
        //         </div>
        //     </div>
        // );
    }
}

LandingPage.propTypes = {
    
};

export default LandingPage;