import {DO, DI, RAH, RE, RI, ME, MI, FA, FI, SE, SOL, SI, LE, LA, LI, TE, TI, OCTAVE} from "./solfege";

export const PITCH_NAMES_LIST = [
    'a', 'bb', 'b', 'c', 'db', 'd', 'eb', 'e', 'f', 'f#', 'g', 'ab'
];
export const PITCH_DISPLAY_NAMES = {
    "abb": "a𝄫",
    "ab": "a♭",
    "a": "a",
    "a#": "a♯",
    "a##": "a𝄪",
    "bbb": "b𝄫",
    "bb": "b♭",
    "b": "b",
    "b#": "b♯",
    "b##": "b𝄪",
    "cbb": "c𝄫",
    "cb": "c♭",
    "c": "c",
    "c#": "c♯",
    "c##": "c𝄪",
    "dbb": "d𝄫",
    "db": "d♭",
    "d": "d",
    "d#": "d♯",
    "d##": "d𝄪",
    "ebb": "e𝄫",
    "eb": "e♭",
    "e": "e",
    "e#": "e♯",
    "e##": "e𝄪",
    "fbb": "f𝄫",
    "fb": "f♭",
    "f": "f",
    "f#": "f♯",
    "f##": "f𝄪",
    "gbb": "g𝄫",
    "gb": "g♭",
    "g": "g",
    "g#": "g♯",
    "g##": "g𝄪"
};

export function getPitchDisplayName(pitchKey){
    if(pitchKey in PITCH_DISPLAY_NAMES){
        return PITCH_DISPLAY_NAMES[pitchKey];
    }
    return pitchKey;
}

/**
 * Gets the pitch of a certain degree of a certain key. 
 * @param {number} key The index of the key.
 * @param {number} degree The index of the scale degree.
 * @returns {string} The internal pitch key—not formatted with musical symbols and not enharmonically correct.
 */
export function getPitchFromDegree(key, degree){
    return PITCH_NAMES_LIST[(key + degree) % PITCH_NAMES_LIST.length];
}

export const PITCH_NAMES = {
    "abb": 10,
    "ab": 11,
    "a": 0,
    "a#": 1,
    "a##": 2,
    "bbb": 0,
    "bb": 1,
    "b": 2,
    "b#": 3,
    "b##": 4,
    "cbb": 1,
    "cb": 2,
    "c": 3,
    "c#": 4,
    "c##": 5,
    "dbb": 3,
    "db": 4,
    "d": 5,
    "d#": 6,
    "d##": 7,
    "ebb": 5,
    "eb": 6,
    "e": 7,
    "e#": 8,
    "e##": 9,
    "fbb": 6,
    "fb": 7,
    "f": 8,
    "f#": 9,
    "f##": 10,
    "gbb": 8,
    "gb": 9,
    "g": 10,
    "g#": 11,
    "g##": 0
};

export const DEGREE_NAMES_LIST = [
    'i', '#i', 'ii', '#ii', 'iii', 'iv', '#iv', 'v', '#v', 'vi', '#vi', 'vii'
];

export const DEGREE_NAMES = {
    "#vii": 0,
    "i": 0,
    "#i": 1,
    "bii": 1,
    "ii": 2,
    "#ii": 3,
    "biii": 3,
    "iii": 4,
    "#iii": 5,
    "biv": 4,
    "iv": 5,
    "#iv": 6,
    "bv": 6,
    "v": 7,
    "#v": 8,
    "bvi": 8,
    "vi": 9,
    "#vi": 10,
    "bvii": 10,
    "vii": 11,
    "bi": 11,
};

export const SCALE_SPELLINGS = {
    //      0       1       2       3       4       5       6       7       8       9       10      11
    "a": [  "a",    "bb",   "b",    "c",    "c#",   "d",    "eb",   "e",    "f",    "f#",   "g",    "g#"    ],
    "a#": [ "a#",   "b",    "b#",   "c#",   "c##",  "d#",   "e",    "e#",   "f#",   "f##",  "g#",   "g##"   ],
    "bb": [ "bb",   "b",    "c",    "c#",   "d",    "eb",   "e",    "f",    "f#",   "g",    "g#",   "a"     ],
    "b": [  "b",    "c",    "c#",   "d",    "d#",   "e",    "f",    "f#",   "g",    "g#",   "a",    "a#"    ],
    "cb": [ "cb",   "c",    "db",   "d",    "eb",   "fb",   "f",    "gb",   "g",    "ab",   "a",    "db"    ],
    "b#": [ "b#",   "c#",   "c##",  "d#",   "d##",  "e#",   "f#",   "f##",  "g#",   "g##",  "a#",   "a##"   ],
    "c": [  "c",    "c#",   "d",    "d#",   "e",    "f",    "f#",   "g",    "g#",   "a",    "a#",   "b"     ],
    "c#": [ "c#",   "d",    "d#",   "e",    "e#",   "f#",   "g",    "g#",   "a",    "a#",   "b",    "b#"    ],
    "db": [ "db",   "d",    "eb",   "e",    "f",    "gb",   "g",    "ab",   "a",    "bb",   "b",    "c"     ],
    "d": [  "d",    "eb",   "e",    "f",    "f#",   "g",    "ab",   "a",    "bb",    "b",   "c",    "c#"    ],
    "d#": [ "d#",   "e",    "e#",   "f#",   "f##",  "g#",   "a",    "a#",   "b",    "b#",   "c#",   "c##"   ],
    "eb": [ "eb",   "e",    "f",    "f#",   "g",    "ab",   "a",    "bb",   "b",    "c",    "c#",   "d"     ],
    "e": [  "e",    "f",    "f#",   "g",    "g#",   "a",    "bb",   "b",    "c",    "c#",   "d",    "d#"    ],
    "fb": [ "fb",   "f",    "gb",   "g",    "ab",   "bbb",  "bb",   "cb",   "c",    "db",   "d",    "eb"    ],
    "e#": [ "e#",   "f#",   "f##",  "g#",   "g##",  "a#",   "b",    "b#",   "c#",   "c##",  "d#",   "d##"   ],
    "f": [  "f",    "f#",   "g",    "g#",   "a",    "bb",   "b",    "c",    "c#",   "d",    "d#",   "e"     ],
    "f#": [ "f#",   "g",    "g#",   "a",    "a#",   "b",    "c",    "c#",   "d",    "d#",   "e",    "e#"    ],
    "gb": [ "gb",   "g",    "ab",   "a",    "bb",   "cb",   "c",    "db",   "d",    "eb",   "e",    "f"     ],
    "g": [  "g",    "ab",   "a",    "bb",   "b",    "c",    "db",   "d",    "eb",   "e",    "f",    "f#"    ],
    "g#": [ "g#",   "a",    "a#",   "b",    "b#",   "c#",   "d",    "d#",   "e",    "e#",   "f#",   "f##"   ],
    "ab": [ "ab",   "a",    "bb",   "b",    "c",    "db",   "d",    "eb",   "e",    "f",    "f#",   "g"     ],
};

export const MAJOR_SCALE = [DO, RE, MI, FA, SOL, LA, TI, DO];

export function scaleDegreeToPitchNumber(scaleDegree) {
    const octaveShift = Math.floor((scaleDegree - 1) / 7);
    const pitch = MAJOR_SCALE[(scaleDegree - 1) % 7];
    return pitch + octaveShift * 12;
}

export const PITCH_NUMBER_TO_SCALE_DEGREE_ACCIDENTAL = ["", "b", "", "b", "", "", "b", "", "b", "", "b", ""];
export const PITCH_NUMBER_TO_SCALE_DEGREE_NEAREST_NOTE = [1, 2, 2, 3, 3, 4, 5, 5, 6, 6, 7, 7];
export function getScaleDegreeFromPitchNumber(pitch) {
    const octave = Math.floor(pitch / OCTAVE);
    const nearestNote = PITCH_NUMBER_TO_SCALE_DEGREE_NEAREST_NOTE[pitch % OCTAVE];
    return PITCH_NUMBER_TO_SCALE_DEGREE_ACCIDENTAL[pitch % OCTAVE] + (nearestNote + octave * 7);
}

/**
 * Gets the enharmonically-correct name of a certain degree of a certain key.
 * @param {string|number} key The index or name of a key. If an index is provided, the default enharmonic representation will be used.
 * @param {number} degree The index of the scale degree.
 * @returns The enharmonically-correct name of the pitch.
 */
export function getEnharmonicNameFromDegree(key, degree){
    if(typeof key === "number")
        return PITCH_DISPLAY_NAMES[SCALE_SPELLINGS[PITCH_NAMES_LIST[key]][degree]];
    if(typeof key === "string")
        return PITCH_DISPLAY_NAMES[SCALE_SPELLINGS[key][degree]];
    throw new Error("Parameter is of invalid type. Expected: number or string, got: " + (typeof key));
}