import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCheck } from "@fortawesome/free-solid-svg-icons";
import "./stylesheets/Dropdown.scss";

class Dropdown extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        const {options, value, placeholder, disabled} = this.props;
        let displayText;
        if(Array.isArray(value)){
            if(value.length == 0){
                displayText = placeholder;
            } else {
                let vals = value.map(i => options.find(j => j.key == i).text);
                const preSlicedLength = vals.length;
                if(this.props.maxDisplayCount > 0) vals = vals.slice(0, this.props.maxDisplayCount);
                displayText = vals.join(", ");
                if(preSlicedLength > this.props.maxDisplayCount) displayText += ` + ${preSlicedLength - this.props.maxDisplayCount} more`;
            }
        } else {
            const selected = options.find(i => i.key == value);
            displayText = selected ? selected.text : placeholder;
        }
        return (
            <div className={`dropdown ${disabled ? "disabled" : ""}`} style={{...this.props.style}}>
                <div className={`display ${this.state.expanded ? "active" : ""}`} onClick={() => {
                    if(!this.props.disabled) this.setState({expanded: !this.state.expanded});
                }}>
                    {displayText}
                    <FontAwesomeIcon icon={faCaretDown} style={{
                        marginRight: "5px"
                    }} />
                </div>
                <div className={`options ${this.state.expanded ? "active" : ""}`}>
                    {
                        options.map((i, idx) => {
                            const isSelected = Array.isArray(value) ?
                                value.indexOf(i.key) != -1 :
                                i.key == value;
                            return (
                                <div
                                    className={`item ${idx == options.length - 1 ? "last" : ""}`}
                                    key={"dropitem-" + idx}
                                    onClick={() => {
                                        this.props.onChange(i.key, !isSelected);
                                        if(!this.props.multiple) this.setState({expanded: false});
                                    }}
                                >
                                    <FontAwesomeIcon icon={faCheck} style={{
                                        visibility: isSelected ? "visible" : "hidden"
                                    }}/>
                                    {i.text}
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        );
    }
}

Dropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({text: PropTypes.string, key: PropTypes.any})).isRequired,
    value: PropTypes.oneOfType([PropTypes.any, PropTypes.arrayOf(PropTypes.string)]),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    multiple: PropTypes.bool,
    maxDisplayCount: PropTypes.number // For multi select: max # of selected options to put as display text
};
Dropdown.defaultProps = {
    placeholder: "",
    multiple: false,
    maxDisplayCount: 0
};

export default Dropdown;