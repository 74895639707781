import React from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import "./stylesheets/MyProjectsPage.scss";
import {Link} from "react-router-dom";
import Loader from "../components/Loader";
import Error from "../components/Error";
import Button from "../components/Button";
import { faChevronRight, faFolderMinus, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import NewProjectModal from "../components/NewProjectModal";
import Popup from "../components/Popup";
import ProfilePic from "../components/ProfilePic";

class MyProjectsPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            error: null,
            data: null,
            showCreateProjectModal: false
        };
        this.db = firebase.firestore();
        this.fetchUserData().then();
    }
    
    fetchUserData = async () => {
        let doc;
        try {
            doc = await this.db.collection("users").doc(this.props.firebaseUser.uid).get();
        } catch(e) {
            console.error(e);
            this.setState({error: "fetch-user-data/unknown"});
            return;
        }
        if(!doc.exists){
            this.setState({error: "fetch-user-data/doc-nonexistent"});
            return;
        }
        this.setState({data: doc.data(), loading: false});
    }

    render(){
        const {data} = this.state;

        if(this.state.error != null){
            return (
                <Error code={this.state.error} onDismiss={() => {
                    this.setState({error: null});
                }} />
            );
        }

        if(this.state.loading || data == null){
            return (
                <Loader />
            );
        }

        return (
            <div>
                {
                    this.state.showCreateProjectModal ? (
                        <NewProjectModal onClose={() => {
                            this.setState({showCreateProjectModal: false});
                        }} onError={(e) => {
                            this.setState({error: e});
                        }} />
                    ) : null
                }
                <div id="my-projects-main-content" className="rails">
                    <h1 id="greeting" className="gradient-text">Hi, {this.props.firebaseUser.displayName}!</h1>
                    <h2 id="greeting-subtitle">What will you create today?</h2>
                    <div style={{textAlign: "center"}}>
                        <Button primary icon={faPlus} content="New project" onClick={() => {
                            this.setState({showCreateProjectModal: true});
                        }} />
                    </div>
                    <div id="project-list">
                    {
                            data.projects.filter(i => i.isInvite).map(i => {
                                return (
                                    <div className="item invite">
                                        <div>
                                            <h3><span className="prefix">You've been invited to</span> {i.title}</h3>
                                            <div className="user-info-display">
                                                <ProfilePic url={i.owner.profilePicture} hoverText={i.owner.displayName} />
                                                <p>{i.owner.displayName} - {i.owner.email}</p>
                                            </div>
                                        </div>
                                        <div>
                                            <Button content="Decline" subtle icon={faTimes} style={{
                                                marginRight: "10px"
                                            }} />
                                            <Button content="Accept" icon={faChevronRight} iconPosition="right" />
                                        </div>
                                    </div>
                                )
                            })
                        }
                        {
                            data.projects.filter(i => !i.isInvite).map(i => {
                                return (
                                    <div className="item">
                                        <div>
                                            <h3>{i.title}</h3>
                                            <div className="user-info-display">
                                                <ProfilePic url={i.owner.profilePicture} hoverText={i.owner.email} />
                                                <p>{i.owner.displayName}</p>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                i.owner.uid == this.props.firebaseUser.uid ? null :
                                                (
                                                    <Popup trigger={
                                                        <Button icon={faFolderMinus} subtle style={{
                                                            marginRight: "10px"
                                                        }} />
                                                    } content="Remove project from dashboard" />
                                                )
                                            }
                                            <Link to={`/studio/${i.id}`}>
                                                <Button content="Open" icon={faChevronRight} iconPosition="right" />
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}

MyProjectsPage.propTypes = {
    
};

export default MyProjectsPage;