import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

const ERROR_CODES = {
    "auth/no-dest": {
        title: "Invalid URL",
        description: "Greetings, brave traveler! You've come to a dead end. Return to the homepage?",
        onDismiss: () => {
            window.location.href = "/";
        }
    },
    "auth/unknown": {
        title: "Could not sign in",
        description: "An unknown error has occurred. Please try again later."
    },
    "auth/account-exists-with-different-credential": {
        title: "Could not sign in",
        description: "You already have an account with a different login provider. Please try again with the service you signed up with."
    },
    "fetch-user-data/unknown": {
        title: "Something went wrong",
        description: "We couldn't get your data from our servers. Please try again later."
    },
    "fetch-user-data/doc-nonexistent": {
        title: "Something went wrong",
        description: "We're still setting up your account. Try again in a moment!"
    },
    "create-project/unauthenticated": {
        title: "Could not create project",
        description: "Try signing out and signing back in again."
    },
    "create-project/invalid-data": {
        title: "Could not create project",
        description: "Please try again later."
    },
    "create-project/auth-error": {
        title: "Could not create project",
        description: "Try signing out and signing back in again."
    },
    "create-project/firebase-error": {
        title: "Could not create project",
        description: "Please try again later."
    }
};

class Error extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        const error = ERROR_CODES[this.props.code];
        return (
            <div className="modal-shade">
                <div className="error-modal">
                    <h1 className="error-title">{error.title}</h1>
                    <p className="error-description">{error.description}</p>
                    <p className="error-code">
                        <b>Error code: </b>
                        {this.props.code}
                    </p>
                    {
                        this.props.onDismiss != null || error.onDismiss != null ? (
                            <Button content="OK" icon={faChevronRight} iconPosition="right" primary onClick={() => {
                                if(this.props.onDismiss != null){
                                    this.props.onDismiss();
                                }
                                if(error.onDismiss != null){
                                    error.onDismiss();
                                }
                            }} />
                        ) : null
                    }
                </div>
            </div>
        );
    }
}

Error.propTypes = {
    code: PropTypes.string.isRequired,
    onDismiss: PropTypes.func
};

export default Error;