import { GUITAR_CHORD_DIAGRAMS } from "./instrument-charts/guitar";
import { UKULELE_CHORD_DIAGRAMS } from "./instrument-charts/ukulele";

const INSTRUMENTS = {
    "guitar": {
        displayName: "Guitar",
        chordDiagrams: GUITAR_CHORD_DIAGRAMS,
        numStrings: 6,
        numFretsInDiagram: 4,
        diagramAspectRatio: 1,
        defaultScale: 1
    },
    "ukulele": {
        displayName: "Ukulele",
        chordDiagrams: UKULELE_CHORD_DIAGRAMS,
        numStrings: 4,
        numFretsInDiagram: 4,
        diagramAspectRatio: 1.5,
        defaultScale: 0.7
    }
}

export default INSTRUMENTS;