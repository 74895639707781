import A0 from "./A0v6.mp3";
import A1 from "./A1v6.mp3";
import A2 from "./A2v6.mp3";
import A3 from "./A3v6.mp3";
import A4 from "./A4v6.mp3";
import A5 from "./A5v6.mp3";
import A6 from "./A6v6.mp3";
import A7 from "./A7v6.mp3";
import C1 from "./C1v6.mp3";
import C2 from "./C2v6.mp3";
import C3 from "./C3v6.mp3";
import C4 from "./C4v6.mp3";
import C5 from "./C5v6.mp3";
import C6 from "./C6v6.mp3";
import C7 from "./C7v6.mp3";
import Ds1 from "./Ds1v6.mp3";
import Ds2 from "./Ds2v6.mp3";
import Ds3 from "./Ds3v6.mp3";
import Ds4 from "./Ds4v6.mp3";
import Ds5 from "./Ds5v6.mp3";
import Ds6 from "./Ds6v6.mp3";
import Ds7 from "./Ds7v6.mp3";
import Fs1 from "./Fs1v6.mp3";
import Fs2 from "./Fs2v6.mp3";
import Fs3 from "./Fs3v6.mp3";
import Fs4 from "./Fs4v6.mp3";
import Fs5 from "./Fs5v6.mp3";
import Fs6 from "./Fs6v6.mp3";
import Fs7 from "./Fs7v6.mp3";

export const PIANO_SAMPLE_URLS = {
    "A0": A0,
    "A1": A1,
    "A2": A2,
    "A3": A3,
    "A4": A4,
    "A5": A5,
    "A6": A6,
    "A7": A7,
    "C1": C1,
    "C2": C2,
    "C3": C3,
    "C4": C4,
    "C5": C5,
    "C6": C6,
    "C7": C7,
    "D#1": Ds1,
    "D#2": Ds2,
    "D#3": Ds3,
    "D#4": Ds4,
    "D#5": Ds5,
    "D#6": Ds6,
    "D#7": Ds7,
    "F#1": Fs1,
    "F#2": Fs2,
    "F#3": Fs3,
    "F#4": Fs4,
    "F#5": Fs5,
    "F#6": Fs6,
    "F#7": Fs7
};
