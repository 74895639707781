import "./stylesheets/SongProfile.scss";
import React from "react";
import PropTypes from "prop-types";
import Truncate from "react-truncate";
import ChordOverview from "./ChordOverview";
import CircleSelector from "./CircleSelector";
import { getPitchDisplayName, PITCH_NAMES_LIST } from "../constants/note-definitions";
import Dropdown from "./Dropdown";
import INSTRUMENTS from "../constants/instruments";
import InstrumentDropdown from "./InstrumentDropdown";
import { parseQuickInput, serializeChord, simplifyChord } from "../constants/chord-serializer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faPlay } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { OUTLINK_IMAGES, RELATIVE_OR_NOTE_NAMES_DROPDOWN_OPTIONS } from "../constants/consts";
import ProgressionPlayer from "./ProgressionPlayer";
import Button from "./Button";

class SongProfile extends React.Component {
    render(){
        const { song, songId, songs, defaultSettings, expanded } = this.props;

        const stopPropagation = (e) => {
            e.stopPropagation();
        };

        const sections = expanded && Object.keys(song.sections).map(sectionName => {
            const section = song.sections[sectionName];
            return <SongProfileSection
                key={sectionName}
                section={section}
                sectionName={sectionName}
                expanded={expanded}
                defaultSettings={defaultSettings}
            />
        })

        const similarTo = [];
        for(let i = 0; i < song.similarTo.length; i++) {
            if(i !== 0) similarTo.push(", ");
            const id = song.similarTo[i];
            if(!(id in songs)) continue;
            similarTo.push(
                <Link to={`/library/song/${id}`} key={i} onClick={stopPropagation}>
                    {songs[id].name}
                </Link>
            );
        }

        const outlinks = Object.keys(song.outlinks).map(id => {
            const url = song.outlinks[id];
            const image = OUTLINK_IMAGES[id];
            return (
                <a href={url} target="_blank" rel="noopener noreferrer" key={id} onClick={stopPropagation}>
                    <img src={image} alt={`${id} logo`} />
                </a>
            );
        });

        return (
            <div className={`song-profile ${expanded ? "expanded" : ""}`} onClick={() => {
                if(expanded) return;
                window.location.href = `/library/song/${songId}`;
            }}>
                <h2 className="name-text" name={songId}>{song.name}</h2>
                <h3 className="artist-text">{song.artist}</h3>
                <div className="tags">
                    {song.tags.map(tag => 
                        <SongProfileTag tag={tag} key={tag} />
                    )}
                </div>
                {
                    expanded && (
                        <h3 className="section-name-text">Commentary</h3>
                    )
                }
                <p className="commentary">
                    {
                        expanded ? song.commentary : (
                            <Truncate lines={3}>{song.commentary}</Truncate>
                        )
                    }
                </p>
                {
                    similarTo.length > 0 && (
                        <p className="similar-to">Similar to: {similarTo}</p>
                    )
                }

                {
                    expanded && (
                        <>
                            <h3 className="section-name-text">Listen On</h3>
                            <div className="outlinks">{outlinks}</div>
                        </>
                    )
                }

                {sections}
            </div>
        );
    }
}

SongProfile.propTypes = {
    song: PropTypes.object.isRequired,
    songId: PropTypes.string.isRequired,
    expanded: PropTypes.bool,
};

class SongProfileSection extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            key: this.props.section.key,
            instrument: Object.keys(INSTRUMENTS)[0],
            mode: "relative",
            showProgressionPlayer: false
        };
    }

    onProgressionPlayerClose = () => {
        this.setState({showProgressionPlayer: false});
    }

    showProgressionPlayer = () => {
        this.setState({showProgressionPlayer: true});
    }

    render() {
        const {showProgressionPlayer} = this.state;
        const {sectionName, section, defaultSettings} = this.props;
        
        const instrument = defaultSettings.instrument || this.state.instrument;
        const mode = defaultSettings.mode || this.state.mode;
        const key = defaultSettings.key || this.state.key;
        const tempo = defaultSettings.tempo || section.tempo;
        const simplify = defaultSettings.simplify;

        const {errorChord, firstError, sequence} = parseQuickInput(section.chords);

        if(firstError) {
            return (
                <p className="error">
                    Looks like the developer made a mistake :( Error parsing {errorChord}: {firstError.message}
                </p>
            )
        }

        const unexpandedSummaryText = sequence.map(chord => {
            if(simplify) chord = simplifyChord(chord);
            return (
                <>
                    {serializeChord(chord, key, true)}
                    <span className="chord-space" />
                </>
            );
        })

        if(!this.props.expanded) {
            return (
                <p className="unexpanded-chords">
                    <span className="unexpanded-section-name">{sectionName}: </span>
                    <span className="unexpanded-chord-summary">{unexpandedSummaryText}</span>
                </p>
            )
        }

        return (
            <div className="song-section" key={`section-${sectionName}`}>
                <ProgressionPlayer
                    visible={showProgressionPlayer}
                    keyNum={key}
                    instrument={instrument}
                    onClose={this.onProgressionPlayerClose}
                    sequence={sequence}
                    tempo={tempo}
                    relative={mode === "relative"}
                    simplify={simplify}
                />
                <h3 className="section-name-text">{sectionName}</h3>
                <p className="info-text">
                    Key: {getPitchDisplayName(PITCH_NAMES_LIST[section.key]).toUpperCase()}
                    <span className="bullet-spacer">•</span>
                    Tempo: {section.tempo}
                </p>
                <div className="section-settings">
                    <InstrumentDropdown value={instrument} disabled={defaultSettings.instrument} onChange={(newInstrument) => {
                        this.setState({instrument: newInstrument});
                    }} />
                    <Dropdown
                        disabled={defaultSettings.mode}
                        value={mode}
                        options={RELATIVE_OR_NOTE_NAMES_DROPDOWN_OPTIONS}
                        onChange={(newVal) => {
                            this.setState({mode: newVal});
                        }}
                    />
                    <CircleSelector labels={
                        PITCH_NAMES_LIST.map(i => getPitchDisplayName(i).toUpperCase())
                    } value={key} disabled={defaultSettings.key} onChange={(val) => {
                        this.setState({key: val});
                    }} />
                    <Button
                        content="Play section"
                        icon={faPlay}
                        onClick={this.showProgressionPlayer}
                    />
                </div>
                <ChordOverview
                    sequence={sequence}
                    keyNum={key}
                    relative={mode === "relative"}
                    instrument={instrument}
                    simplify={simplify}
                />
            </div>
        );
    }
}

SongProfileSection.propTypes = {
    sectionName: PropTypes.string,
    section: PropTypes.object
}

class SongProfileTag extends React.Component {
    render() {
        const stopPropagation = (e) => {
            e.stopPropagation();
        };

        return (
            <Link to={`/library/tag/${encodeURIComponent(this.props.tag)}`} className="song-profile-tag" onClick={stopPropagation}>
                <div>{this.props.tag}</div>
            </Link>
        );
    }
}

SongProfileTag.propTypes = {
    tag: PropTypes.string
};

export default SongProfile;
export { SongProfileTag };
