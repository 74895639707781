import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import "./stylesheets/Loader.scss";
class Loader extends React.Component {
    constructor(props){
        super(props);
        this.state = {rotation: 0};

        setInterval(() => {
            this.setState({rotation: this.state.rotation + 3});
        }, 10);
    }
    
    render(){
        return (
            <div className="loader-shade">
                <FontAwesomeIcon icon={faCircleNotch} className="loader-icon" style={{
                    transform: `rotate(${this.state.rotation}deg)`
                }} />
            </div>
        );
    }
}

Loader.propTypes = {
    
};

export default Loader;