import React from "react";

class EditorPage extends React.Component {
    constructor(props){
        super(props);
        this.state = {};
    }
    
    render(){
        return (
            <div>
                <h1>This is the editor page!</h1>
            </div>
        );
    }
}

EditorPage.propTypes = {
    
};

export default EditorPage;